<template>
  <div>
    <el-dialog
      title="上传货品图片"
      :visible="showDialog"
      :before-close="handleClose"
      width="50%"
    >
      <el-upload
        :action="domain"
        :data="qiNiuData"
        :class="{ hide: hideUpload }"
        class="upload-idCard"
        list-type="picture-card"
        :file-list="defaultImg"
        :onChange="handleChange"
        ref="imgRef"
        :on-preview="handleShow"
        :on-error="handleError"
        :on-success="handleAvatarSuccess"
        :on-remove="handleRemove"
        :on-exceed="handleExceed"
        :before-upload="beforeAvatarUpload"
        :limit="3"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="isShowImg" width="50%" size="tiny">
        <img width="100%" :src="myFileUrl" alt="" />
      </el-dialog>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
    },
    detailedForm: {
      Object,
    },
    imgUrl:{
      Object
    }
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      domain: "https://upload-z2.qiniup.com",
      qiNiuData: {},
      defaultImg: [],

      hideUpload: false,
      limitCount: 1,
      isShowImg: false,
      isShowPdf: false,
      isType: true,
      myHashUrl: this.fileUrl,
      myFileUrl: "",
      myFileName: "",
      pageNum: 1,
      SaveData: {
        pageNum: 1,
        pageSize: 10,
      },
      pageTotalNum: 1,
      fileImageType: "",
      BASE_URL: "https://gylian.yiputouzi.com/",
      // showImage: "http://image.dogohome.com/Flmwv-qq1q8i6liEDw34zecRaQyG"
      images_path: [],
    };
  },
  created() {
    // this.getQiNiuToken();
    // this.detailedForm.images_path = JSON.parse(this.detailedForm.images_path);
    // console.log(this.detailedForm);
    // this.detailedForm.images_path.forEach((item) => {
    //   let obj = new Object();
    //   obj.response = {};
    //   let img_path = {};
    //   obj.response.hash = item.hash;
    //   obj.url = this.BASE_URL + item.hash;
    //   img_path.hash = item.hash;
    //   img_path.url = this.BASE_URL + item.hash;
    //   this.images_path.push(img_path);
    //   this.defaultImg.push(obj);
    // });
    // this.$emit("images_path", this.images_path);
  },
  watch: {
    showDialog() {
      if(this.showDialog){
        this.getQiNiuToken();
        // 创建采购清单进入

        if(this.imgUrl !== undefined){
          this.echoImgUrl(JSON.parse(this.imgUrl))
          return
        }
        // 列表为空退出
        if(!this.detailedForm) return
        if(typeof this.detailedForm.images_path == 'string'){
          this.detailedForm.images_path = JSON.parse(this.detailedForm.images_path)
        }
        this.echoImgUrl(this.detailedForm.images_path)

        // this.detailedForm.images_path.forEach((item) => {
        // let obj = new Object();
        // obj.response = {};
        // let img_path = {};
        // obj.response.hash = item.hash;
        // obj.url = this.BASE_URL + item.hash;
        // img_path.hash = item.hash;
        // img_path.url = this.BASE_URL + item.hash;
        // this.images_path.push(img_path);
        // this.defaultImg.push(obj);
      // });
      this.$emit("images_path", this.images_path);
      }
      
    },
    // showDialog() {
    //   if (this.showDialog) {
    //     this.getQiNiuToken();
    //   }
    // },
  },
  methods: {
    echoImgUrl(data){
      data.forEach((item) => {
        let obj = new Object();
        obj.response = {};
        let img_path = {};
        obj.response.hash = item.hash;
        obj.url = this.BASE_URL + item.hash;
        img_path.hash = item.hash;
        img_path.url = this.BASE_URL + item.hash;
        this.images_path.push(img_path);
        this.defaultImg.push(obj);
      });
    },
    confirm() {
      this.$emit("handleClose");
      this.$emit("images_path", this.images_path);
      this.defaultImg=[]
      this.images_path = []
    },
    handleClose() {
      this.$emit("handleClose");
      this.$emit("images_path", this.images_path);
      this.defaultImg=[]
      this.images_path = []
    },
    async getQiNiuToken() {
      let { data: res } = await this.$http.get("v1/common/qiniu/token");
      this.qiNiuData.token = res.data.token;
    },

    // 下载文件
    handleDownFile() {
      console.log("下载文件");
    },
    // 上传文件时的钩子函数
    beforeAvatarUpload(file) {
      // console.log("上传文件时的钩子函数");
      return this.setFileValidation(file);
    },
    // 文件验证
    setFileValidation(file) {
      this.qiNiuData.key = file.name;
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      let isJPG = (type == "jpg" || type == "png" || type == "gif") && true;
      !isJPG &&
        this.$message.error("上传的文件不符合规范!,支持格式:png/jpg/gif");
      return isJPG;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多只能上传3张图片，请删除后再添加`);
    },
    // 选择时触发
    handleChange(file) {
      // console.log("选择时触发", file);
      // this.hideUpload = this.defaultImg.length >= this.limitCount;
    },
    // 成功之后判断类型
    isSuccessFileType(key) {
      // console.log("成功之后判断类型");
      // let arr = key.split(".");
      // return arr[arr.length - 1];
    },
    // 返回错误
    handleError() {
      this.$message({
        message: "文件上传失败",
        type: "error",
        duration: 5 * 1000,
      });
    },

    // 成功返回
    handleAvatarSuccess(res, file) {
      // console.log("成功返回", res);
      this.myFileUrl = this.BASE_URL + res.hash;
      let img = { hash: res.hash, url: this.myFileUrl };
      this.images_path.push(img);
    },

    handleRemove(file, fileList) {
      // console.log("删除", file, fileList);
      if (fileList.length > 0) {
        let imgUrl = {};
        this.images_path = [];
        fileList.forEach((item) => {
          imgUrl = {
            hash: item.response.hash,
            url: this.BASE_URL + item.response.hash,
          };
          this.images_path.push(imgUrl);
        });
      } else {
        this.images_path = [];
      }
      // console.log(this.images_path);
    },
    // 点击文件列表中已上传的文件时的钩子
    async handleShow(file) {
      this.myFileUrl = file.url;
      this.isShowImg = true;
      // this.myFileUrl = this.BASE_URL + this.myHashUrl;
      // this.isType && (await this.openFile());
    },
  },
};
</script>
<style scoped>
</style>