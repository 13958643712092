<template>
  <div>
    <!-- 头部状态条 -->
    <HeaderInfo></HeaderInfo>

    <!-- banner部分 -->
    <div class="banner_box">
      <el-carousel height="420px" :interval="5000" arrow="always">
        <el-carousel-item v-for="(img, index) in imgList" :key="index">
          <img v-bind:src="img.url" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 内容部分 -->
    <div class="content_box">
      <div class="content_left">
        <!-- 采购单列表 -->
        <div class="purchase_list">
          <!-- 标题 -->
          <div class="purchase_title">
            <p class="title">采购单列表</p>
            <p class="more" @click="goPurchaseList">更多 >>></p>
          </div>
          <div
            class="list_content"
            v-for="(item, index) in purchaseList"
            :key="index"
            @click="goPurchase(item.id)"
          >
            <div class="purchase_list_info">
              <div class="list_img">
                <img :src="item.cover" alt="" />
                <div class="img_category">
                  {{ item.category.purchase_name }}
                </div>
              </div>
              <div class="purchase_list_right">
                <div class="purchase_name_info">
                  <div class="purchase_name overflow1">
                    {{ item.purchase_name }}
                  </div>
                  <div
                    class="purchase_statu"
                    :class="item.purchase_status === 1 ? '' : 'secondary_color'"
                  >
                    {{ item.purchase_status == 1 ? "报价中" : "报价结束" }}
                  </div>
                </div>
                <div class="purchase_label">
                  <div class="purchase_label_left">
                    <p>{{ item.invoice.name }}</p>
                    <p>{{ item.quote_price.name }}</p>
                    <p v-if="item.tax">{{ item.tax.name }}</p>
                  </div>
                  <div class="purchase_time">截止时间：{{ item.deadline }}</div>
                </div>
                <!-- 发布单位、用材地址 -->
                <div class="purchase_address overflow1">
                  <span style="margin-right: 20px"
                    >发布单位：{{ item.company_name }}</span
                  >
                  <span>用材地址：{{ item.address }}</span>
                </div>
                <!-- 采购数量 -->
                <div class="purchase_label">
                  <div class="purchase_count">
                    采购 {{ item.purchase_count }} 件
                  </div>
                  <div class="purchase_btn_info">查看详情</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 供应商列表 -->
        <div class="purchase_list" style="margin-top: 20px">
          <!-- 标题 -->
          <div class="purchase_title">
            <p class="title">供应商列表</p>
            <p class="more" @click="goSupplierList">更多 >>></p>
          </div>
          <div
            class="list_content"
            v-for="(item, index) in supplierList"
            :key="index"
            @click="goSupplier(item.id)"
          >
            <div class="purchase_list_info">
              <div class="list_img">
                <img :src="item.cover" alt="" />
              </div>
              <div class="supple_list">
                <div class="supple_title_box">
                  <p class="supple_title">{{ item.company_name }}</p>
                  <p class="purchase_statu" v-if="item.provider_number > 0">
                    {{ item.provider_number }}条供应
                  </p>
                  <p class="purchase_statu" v-else>暂无供应</p>
                </div>
                <p class="supple_list_info">
                  联系电话：{{ item.contact_name }} {{ item.contact_phone }}
                </p>
                <p class="supple_list_info">公司地址：{{ item.address }}</p>
                <p class="supple_list_info">
                  主营内容：{{ item.business_info }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧内容 -->
      <div class="right_code">
        <!-- 二维码内容 -->
        <div class="code_list">
          <div class="code_info">
            <img src="../assets/image/weChat.jpg" alt="" />
            <p>关注供应链微信公众号</p>
          </div>
          <div class="code_info">
            <img src="../assets/image/weChat_miniPrograms.jpg" alt="" />
            <p>体验供应链微信小程序</p>
          </div>
        </div>
        <!-- 公告内容 -->
        <div class="notice_box">
          <div class="notice_title">
            <p>公告</p>
            <!-- <p style="cursor: pointer" @click="goNoticeList">更多 >>></p> -->
          </div>
          <div
            class="notice_list"
            v-for="(item, index) in noticeList"
            :key="index"
            @click="goNotice(item.id)"
          >
            <p class="notice_info_title overflow2">{{ item.name }}</p>
            <p class="notice_info_time">{{ item.create_time }}</p>
          </div>
        </div>
      </div>
    </div>
    <FooterInfo></FooterInfo>
  </div>
</template>

<script>
import { Loading } from "element-ui";
export default {
  components: {
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 4,
      },
      purchaseList: [],
      supplierList: [],
      noticeList: [],
      imgList: [
        {
          url:
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fyouimg1.c-ctrip.com%2Ftarget%2Ftg%2F004%2F531%2F381%2F4339f96900344574a0c8ca272a7b8f27.jpg&refer=http%3A%2F%2Fyouimg1.c-ctrip.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1621910976&t=5f5b28f197296858c6b8ba47a53c6821",
        },
        {
          url:
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fyouimg1.c-ctrip.com%2Ftarget%2Ftg%2F004%2F531%2F381%2F4339f96900344574a0c8ca272a7b8f27.jpg&refer=http%3A%2F%2Fyouimg1.c-ctrip.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1621910976&t=5f5b28f197296858c6b8ba47a53c6821",
        },
      ],
      loading:true,
      href:'',
    };
  },
  created() {
    this.href = window.location.href;
    this.getPurchaseList();
    this.getSupplierList();
    this.getNoticeList()
  },
  watch:{
    href(newValue,oldValue){
      if (window.location.href.indexOf('code') >= 0) {
          let hrefCode = window.location.href.split('?')[1];
          let code = hrefCode.substring(5, hrefCode.indexOf('&'));
          this.getCode(code);
      }
    }
  },
  
  methods: {
    
    // 获取采购单列表
    async getPurchaseList() {
      // let loadingInstance = Loading.service();
      const { data: res } = await this.$http.get("v1/purchase/list", {
        params: this.queryInfo,
      });
      this.purchaseList = res.data.list;
      // this.$nextTick(() => {
      //   // 以服务的方式调用的 Loading 需要异步关闭
      //   loadingInstance.close();
      // });
    },
    // 获取供应商列表
    async getSupplierList() {
      const { data: res } = await this.$http.get("v1/supplier/list", {
        params: this.queryInfo,
      });
      this.supplierList = res.data.list;
    },

    // 获取公告列表
    async getNoticeList(){
      const { data: res } = await this.$http.get("v1/common/message/list", {
        params:{
          page: 1,
          size:10,
          platform_message_category_id:3
        }
      });
      this.noticeList = res.data.list;
    },

    // 跳转采购单详情
    goPurchase(id) {
      // console.log("跳转采购单详情" + id);
      this.$router.push({
        path: "/purchaseDetail",
        query: {
          id: id,
        },
      });
    },
    // 跳转采购单列表
    goPurchaseList() {
      this.$router.push("/purchaseList");
    },
    // 跳转供应商列表
    goSupplier(id) {
      // console.log("跳转供应商详情" + id);
      this.$router.push({
        path: "/supplierDetail",
        query: {
          id: id,
        },
      });
    },
    // 跳转供应商列表
    goSupplierList() {
      this.$router.push("/supplyList");
    },
    // 跳转公告列表
    goNoticeList() {
      console.log("跳转公告列表");
    },
    // 跳转公告详情
    goNotice(id) {
      this.$router.push({
        path: "/noticeDeatil",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.content_box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}
.purchase_list {
  width: 800px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #eee;
  overflow: hidden;
}
.right_code {
  width: 380px;
}
.purchase_list_info {
  display: flex;
}
.purchase_title {
  /* padding: 10px 0 10px 0; */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #20ab3a;
}
.title {
  display: inline-block;
  background: #20ab3a;
  color: #fff;
  font-size: 20px;
  padding: 10px 24px 10px 24px;
}
.more {
  padding: 10px 10px;
  color: #666;
  font-size: 18px;
  cursor: pointer;
}
.list_content {
  padding: 20px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}
.list_img {
  width: 130px;
  height: 130px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.img_category {
  position: absolute;
  top: 0;
  left: 0;
  background: #20ab3a;
  color: #fff;
  font-size: 13px;
  padding: 2px 5px 2px 5px;
}
.purchase_list_right {
  margin-left: 20px;
  width: 610px;
}
.purchase_name_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.purchase_name {
  font-size: 22px;
  color: #333;
}
.purchase_statu {
  color: #20ab3a;
  font-size: 20px;
  font-weight: 500;
  text-align: right;
}
.secondary_color {
  color: #dc5b56;
}
.purchase_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.purchase_label_left {
  font-size: 13px;
  display: flex;
}
.purchase_label_left > p {
  margin-right: 10px;
  padding: 2px 8px 2px 8px;
  background: #20ab3a;
  color: #fff;
}
.purchase_time {
  color: #333;
  font-size: 16px;
}
.purchase_address {
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}
.purchase_count {
  color: #dc5b56;
  font-size: 20px;
}
.purchase_btn_info {
  background: #20ab3a;
  border-radius: 5px;
  padding: 5px 12px;
  color: #fff;
  font-size: 15px;
}

/* 供应商列表 */
.supple_list {
  /* display: flex; */
  margin-left: 20px;
  width: 610px;
}
.supple_title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.supple_title {
  color: #333;
  font-size: 22px;
}
.supple_list_info {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

/* 二维码 */
.code_list {
  height: 200px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #eee;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.code_info {
  width: 160px;
  height: 160px;
}
.code_info {
  text-align: center;
}
.code_info > p {
  font-size: 15px;
  color: #333333;
  margin-top: 10px;
}
/* 公告内容 */
.notice_box {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #eee;
  overflow: hidden;
  margin-top: 20px;
}
.notice_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background: #20ab3a;
  color: #fff;
  font-size: 18px;
  height: 50px;
}
.notice_list {
  padding: 15px 0;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}
.notice_info_title {
  color: #333;
  font-size: 18px;
  padding: 0px 20px;
}
.notice_info_time {
  color: #999;
  font-size: 14px;
  margin-top: 6px;
  padding: 0px 20px;
}
</style>
