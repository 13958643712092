<template>
  <div>
    <UserHead></UserHead>
    <!-- banner -->
    <div class="banner">
      <div>banner部分</div>
    </div>
    <!-- 内容部分 -->
    <div class="settle_box">
      <div class="detailed_box_info">
        <p class="detailed_box_info_title">采购单信息</p>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/purchase' }"
            >采购清单
          </el-breadcrumb-item>
          <el-breadcrumb-item>采购单信息</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="supply_box">
        <div>
          <p class="info_title">基本信息</p>
          <div class="supply_table">
            <el-form
              ref="purchaseFormRef"
              :model="purchaseForm"
              label-width="100px"
              class="supply_table"
            >
              <el-form-item
                label="采购类型"
                prop="purchase_category_id"
                :rules="[
                  { required: true, message: '请选择类型', trigger: 'blur' },
                ]"
              >
                <el-cascader
                  placeholder="请选择类型"
                  v-model="purchaseForm.purchase_category_id"
                  :options="suppleOptions"
                  :props="suppleProps"
                  filterable
                  clearable
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="采购单名称"
                prop="purchase_name"
                :rules="[
                  {
                    required: true,
                    message: '请输入采购单名称',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="purchaseForm.purchase_name"></el-input>
              </el-form-item>
              <el-form-item
                label="截止时间"
                prop="deadline"
                :rules="[
                  {
                    required: true,
                    message: '请输入截止时间',
                    trigger: 'blur',
                  },
                ]"
              >
                <!-- <el-input v-model="purchaseForm.deadline"></el-input> -->
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="purchaseForm.deadline"
                  type="date"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="发布单位"
                prop="company_name"
                :rules="[
                  {
                    required: true,
                    message: '请输入发布单位',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="purchaseForm.company_name" @input="updateView"></el-input>
              </el-form-item>
              <el-form-item
                label="联系电话"
                prop="contact_phone"
                :rules="[
                  {
                    required: true,
                    message: '请输入联系电话',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="purchaseForm.contact_phone" @input="updateView"></el-input>
              </el-form-item>
              <el-form-item
                label="联系人"
                prop="contact_name"
                :rules="[
                  {
                    required: true,
                    message: '请输入联系人',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="purchaseForm.contact_name" @input="updateView"></el-input>
              </el-form-item>
              <el-form-item
                label="进场车限制"
                prop="car_limit"
                :rules="[
                  {
                    required: true,
                    message: '请输入进场车限制',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="purchaseForm.car_limit"></el-input>
              </el-form-item>
              <el-form-item
                label="进场时间"
                prop="enter_limit"
                :rules="[
                  {
                    required: true,
                    message: '请输入进场时间',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="purchaseForm.enter_limit"></el-input>
              </el-form-item>
              <el-form-item
                label="报价要求"
                prop="quote_type"
                :rules="[
                  {
                    required: true,
                    message: '请选择报价要求',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio v-model="purchaseForm.quote_type" :label="1"
                  >到货价</el-radio
                >
                <el-radio v-model="purchaseForm.quote_type" :label="2"
                  >上车价</el-radio
                >
              </el-form-item>
              <el-form-item
                label="发票要求"
                prop="invoice_type_id"
                :rules="[
                  {
                    required: true,
                    message: '请选择发票要求',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio v-model="purchaseForm.invoice_type_id" :label="1"
                  >增值税专用发票</el-radio
                >
                <el-radio v-model="purchaseForm.invoice_type_id" :label="2"
                  >增值税普通发票</el-radio
                >
                <el-radio v-model="purchaseForm.invoice_type_id" :label="3"
                  >普通发票</el-radio
                >
                <el-radio v-model="purchaseForm.invoice_type_id" :label="4"
                  >不需要发票</el-radio
                >
              </el-form-item>
              <el-form-item
                label="含税要求"
                prop="purchase_tax_id"
                :rules="[
                  {
                    required: true,
                    message: '请选择含税要求',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio v-model="purchaseForm.purchase_tax_id" :label="1"
                  >不含税</el-radio
                >
                <el-radio v-model="purchaseForm.purchase_tax_id" :label="2"
                  >含税</el-radio
                >
              </el-form-item>
              <el-form-item label="税点">
                <el-input v-model="purchaseForm.tax_point"></el-input>
              </el-form-item>
              <el-form-item
                label="采购地址"
                prop="address"
                :rules="[
                  {
                    required: true,
                    message: '请输入采购地址',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="purchaseForm.address"></el-input>
              </el-form-item>
              <el-form-item label="备注信息">
                <el-input
                  type="textarea"
                  v-model="purchaseForm.other"
                ></el-input>
              </el-form-item>
              <el-form-item label="相关附件" class="itemImg">
                <UploadData
                  @images_path="imagesPath"
                  :echoData="echoData"
                  :purchaseForm="purchaseForm"
                ></UploadData>
              </el-form-item>
              <el-form-item>
                <div class="save_btn" @click="save">提交基本信息</div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-if="!successSave">
          <DetailedLists :successSave="successSave" :id="id"></DetailedLists>
        </div>
      </div>
    </div>
    <FooterInfo></FooterInfo>
  </div>
</template>
<script>
import UserHead from "../components/UserHeader.vue";
import FooterInfo from "../components/Footer";
import DetailedLists from "../components/DetailedLists";
import UploadData from "../components/UploadData.vue";

import { Loading } from "element-ui";
export default {
  components: {
    UserHead,
    FooterInfo,
    UploadData,
    DetailedLists,
  },
  data() {
    return {
      purchaseForm: {},
      suppleSelect: [],
      suppleOptions: [],
      suppleProps: {
        expandTrigger: "hover",
        value: "purchase_id",
        label: "purchase_name",
        children: "children",
      },
      // quoteRadio: "",
      // invoiceRadio: "",
      // taxRadio: "",
      id: null,
      successSave: false,
      echoData: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getSuppleType();
    if (this.id) {
      this.getPurchaseForm();
    }else{
      let user = JSON.parse(localStorage.getItem("USER"))
      this.purchaseForm.company_name = user.supplier.company_name
      this.purchaseForm.contact_phone = user.supplier.contact_phone
      this.purchaseForm.contact_name = user.supplier.contact_name
    }
  },
  methods: {
    // 更新视图
    updateView(){
      this.$forceUpdate();
    },
    async getPurchaseForm() {
      let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get(
        `purchase/detail?purchase_id=${this.id}`
      );

      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
      if (res.code !== 200)
        return this.$message.error("信息获取失败，请稍后再试");
      this.purchaseForm = res.data;
      this.purchaseForm.purchase_tax_id = res.data.tax.id;
      this.purchaseForm.quote_type = res.data.quote_price.quote_type_id;
      this.purchaseForm.invoice_type_id = res.data.invoice.invoice_type_id;
      if(res.data.category.parent_id === 0){
        this.purchaseForm.purchase_category_id = [res.data.category.purchase_id,res.data.category.purchase_id];
      }else{
        this.purchaseForm.purchase_category_id = [res.data.category.parent_id,res.data.category.purchase_id];
      }

      this.echoData = true;
      this.successSave = true;
      // console.log(this.purchaseForm);
    },
    async getSuppleType() {
      let { data: res } = await this.$http.post("v1/user/ident/type");
      // let { data: res } = await this.$http.get("v1/common/supplier/type");
      this.suppleOptions = res;
    },
    async getQiNiuToken() {
      let { data: res } = await this.$http.get("v1/common/qiniu/token");
      this.qiNiuData.token = res.data.token;
    },
    save() {
      this.$refs.purchaseFormRef.validate((vaild) => {
        if (!vaild) return false;
        this.successSave = true;
        this.purchaseForm.purchase_category_id = this.purchaseForm.purchase_category_id[1]
        if(this.id){
          this.putFormPurchaser('purchase/update')
        }else{
          this.postFormPurchaser('purchase/create')
        }

      });
    },
    async postFormPurchaser(url){
      let { data: res } = await this.$http.post(url,{...this.purchaseForm});
      if(res.code === 200){
         this.$message.success("创建成功");
        this.$router.go(-1); 
      }
    },
    async putFormPurchaser(url){
      let { data: res } = await this.$http.put(url,{...this.purchaseForm});
      if(res.code === 200){
         this.$message.success("修改成功");
        this.$router.go(-1); 
      }
    },

    // 组件返回的数据保存进表单中
    imagesPath(e) {
      this.purchaseForm.images_path = JSON.stringify(e);
    },
  },
};
</script>
<style scoped>
.banner {
  width: 1200px;
  height: 240px;
  line-height: 240px;
  text-align: center;
  margin: 20px auto;
  background: #d8d8d8;
}

.settle_box {
  width: 100%;
  background: #fff;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
  /* margin-top: 20px; */
}

.supply_box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 40px;
}
.info_title {
  margin-top: 30px;
  color: #333;
  font-size: 22px;
}
.supply_table {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.supply_table > .el-form-item {
  margin-right: 100px;
  width: 500px;
}
.itemImg {
  width: 100% !important;
}
.save_btn {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 120px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 4px;
}

.el-cascader {
  width: 100%;
}
.el-date-editor {
  width: 100% !important;
}

.pagination {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
</style>