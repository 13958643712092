<template>
  <div>
    <UserHead></UserHead>
    <!-- banner -->
    <div class="banner">
      <div>banner部分</div>
    </div>
    <!-- 内容部分 -->
    <div class="settle_box">
      <div class="detailed_box_info">
        <p class="detailed_box_info_title">报价明细</p>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/purchase' }"
            >采购清单
          </el-breadcrumb-item>
          <el-breadcrumb-item>报价明细</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="supply_box">
        <p class="purchaseName">{{ purchaseName }}</p>
        <el-table :data="quoteList" stripe style="width: 100%" border>
          <el-table-column type="index" label="#"> </el-table-column>
          <el-table-column
            prop="supplier.company_name"
            label="报价单位"
            width="250px"
          >
          </el-table-column>
          <el-table-column prop="total_number" label="报价类目">
          </el-table-column>

          <el-table-column label="不含税合计">
            <template slot-scope="scope">
              <p>{{ scope.row.no_tax_total_money | tofixed }}</p>
            </template>
          </el-table-column>

          <el-table-column label="含税合计">
            <template slot-scope="scope">
              <p>{{ scope.row.total_money | tofixed }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="supplier.contact_phone" label="联系电话">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="openInfo(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="query.page"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="detailedListTotal"
          :hide-on-single-page="hideSinglePage"
        >
        </el-pagination>
      </div>
    </div>
    <FooterInfo></FooterInfo>

    <QuoteDialog
      :quoteDialogVisible.sync="quoteDialogVisible"
      :item="item"
    ></QuoteDialog>
  </div>
</template>
<script>
import UserHead from "../components/UserHeader.vue";
import FooterInfo from "../components/Footer";
import QuoteDialog from "../components/QuoteDialog";

import { Loading } from "element-ui";
export default {
  components: {
    UserHead,
    FooterInfo,
    QuoteDialog,
  },
  data() {
    return {
      query: {
        page: 1,
        size: 10,
        purchase_id: "",
      },
      purchaseName: "",
      quoteList: [],
      quoteDialogVisible: false,
      item: {},
      detailedListTotal: 0,
      hideSinglePage: true,
    };
  },
  created() {
    this.query.purchase_id = this.$route.query.id;
    this.getQuoteList();
  },
  filters: {
    tofixed(value) {
      let tempVal = parseFloat(value).toFixed(2);
      return tempVal;
    },
  },
  methods: {
    async getQuoteList() {
      let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("purchase/supplier", {
        params: this.query,
      });
      this.$nextTick(() => {
        loadingInstance.close();
      });
      if (res.code !== 200)
        return this.$message.error("清单列表获取失败，请稍后重试");
      this.quoteList = res.data.list;
      this.purchaseName = this.quoteList[0].purchase.purchase_name;
      this.detailedListTotal = res.data.count;
    },
    // 分页
    handleSizeChange(val) {
      this.query.size = val;
      this.getQuoteList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getQuoteList();
    },
    async openInfo(row) {
      this.item = row;
      // let loadingInstance = Loading.service();
      // let { data: res } = await this.$http.get(`purchase/supplier/detail?`);
      // this.$nextTick(() => {
      //   loadingInstance.close();
      // });
      // if (res.code !== 200)
      //   return this.$message.error("清单列表获取失败，请稍后重试");
      // console.log(res);
      this.quoteDialogVisible = true;
    },
  },
};
</script>
<style scoped>
.banner {
  width: 1200px;
  height: 240px;
  line-height: 240px;
  text-align: center;
  margin: 20px auto;
  background: #d8d8d8;
}

.settle_box {
  width: 100%;
  background: #fff;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
  /* margin-top: 20px; */
}

.supply_box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
.info_title {
  margin-top: 30px;
  color: #333;
  font-size: 22px;
}
.supply_table {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.supply_table > .el-form-item {
  margin-right: 100px;
  width: 500px;
}
.itemImg {
  width: 100% !important;
}
.save_btn {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 120px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 4px;
}
.purchaseName {
  font-size: 20px;
  font-weight: bold;
  color: #20ab3a;
  margin-bottom: 10px;
}

.el-cascader {
  width: 100%;
}
.pagination {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
}
</style>