<template>
  <div>
    <div class="headBox">
      <div class="head">
        <ul class="head_ul" v-if="!isLogin">
          <li class="head_ul_li" @click="toLogin">登录</li>
          <!-- <li>|</li>
          <li class="head_ul_li" @click="toRegister">免费注册</li> -->
        </ul>
        <ul class="head_ul" v-else>
          <li class="head_ul_li" @click="toUserInfo">
            <!-- 欢迎您,{{ username.contact_name }} -->
            欢迎您,{{ username.nickName }}
          </li>
          <li>|</li>
          <li class="head_ul_li" @click="signOut">注销</li>
        </ul>
      </div>
    </div>

    <!-- 导航栏 -->
    <div class="nav_box">
      <ul class="nav_list">
        <li @click="goHome">苗木市场</li>
        <li @click="goSettle">平台入驻</li>
        <li @click="goSupply">我的供应</li>
        <li @click="goQuote">我的报价</li>
        <li @click="goPurchase">我的采购</li>
        <li @click="goFinanceDetail">融资申请</li>
      </ul>
    </div>
  </div>
</template>
<script>
import store from "../store";

export default {
  data() {
    return {
      token: store.state.account.loginToken,
      username: store.state.account.user,
      isLogin: false,
    };
  },
  created() {
    let token = localStorage.getItem("TOKEN");
    let username = localStorage.getItem("USER");

    if (token && username) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    toLogin() {
      this.$router.push("/login");
    },
    toRegister() {
      this.$router.push("/register");
    },
    toUserInfo() {
      this.$router.push("/userInfo");
    },
    // 退出登录
    signOut() {
      this.$db.clear();
      // 刷新页面

      this.$router.push("/");
      location.reload();
      this.isLogin = false;
    },

    goSettle() {
      this.$router.push("/userInfo");
    },
    goSupply() {
      this.$router.push("/supply");
    },
    goQuote() {
      this.$router.push("/quote");
    },
    goPurchase() {
      this.$router.push("/purchase");
    },
    async goFinanceDetail() {
      let { data: res } = await this.$http.get("v1/financing/detail");
      if (res.code === 10009) return this.$message.error(res.msg);
      this.$router.push("/financeDetail");
    },
  },
};
</script>
<style scoped>
.headBox {
  width: 100%;
  height: 36px;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
}
.logoBox {
  width: 100%;
  background: #fff;
  padding: 10px 0 10px 0;
}
.head {
  width: 1200px;
  margin: 0 auto;
  height: 36px;
  line-height: 36px;
  display: flex;
  justify-content: flex-end;
}
.head_ul {
  display: flex;
  height: 100%;
  list-style: none;
  font-size: 13px;
}
.head_ul > li {
  margin-left: 10px;
  color: #ccc;
  cursor: pointer;
}
.head_ul_li:hover {
  color: #dc5b56;
}

.nav_box {
  width: 100%;
  height: 60px;
  background: #20ab3a;
}
.nav_list {
  width: 1200px;
  height: 60px;
  margin: 0 auto;
  display: flex;
  line-height: 60px;
  /* align-items: center; */
  list-style: none;
}
.nav_list > li {
  padding: 0px 30px 0px 30px;
  color: #fff;
  position: relative;
  cursor: pointer;
}
.nav_list > li:hover {
  background: #159b2e;
}
/* .nav_list > li:hover .nav_list_children {
  display: block;
}
.nav_list_children {
  display: none;
  width: 120px;
  position: absolute;
  left: 0;
  background: rgba(68, 68, 68, 0.9);
  list-style: none;
  padding: 0px 30px 0px 30px;
  z-index: 50;
} */
</style>