import axios from 'axios'

import router from './../router';
import { Notification, MessageBox, Message } from 'element-ui'
import store from '../store'
import db from './localstorage'


// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL:process.env.VUE_APP_API_URL,
  // baseURL: 'http://gongyingliantest.yiputouzi.com/api/',
  // baseURL: 'https://gongyinglian.yiputouzi.com/api/',
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 有 token就带上
  if (store.state.account.loginToken) {
    config.headers.token = store.state.account.loginToken
  }
  return config
}, error => {
    console.log(error)
    Message.error({ message: '请求超时!' });
    // Promise.reject(error)
    // return error
})

// 拦截响应
service.interceptors.response.use((config) => {
  if (!config.data) {
    config.data = {}
    return config
  }
  // if (config.data.code === 100) {
  //   Notification({
  //     title: '系统提示',
  //     message: config.data.message,
  //     type: 'error'
  //   })
  // }
  // return config

  switch (config.data.code) {
    case 10002:
      Notification({
        title: '系统提示',
        message: config.data.message,
        type: 'error'
      })
      return null;
    case 101:
      Notification({
        title: '系统提示',
        message: '请先进行采购商认证 ！',
        type: 'error'
      });
      return null;
    case 10003:
      db.clear()
      Message({
        message: '请先登录 ！',
        type: 'warning'
      })
      router.replace('/login')
      // MessageBox.confirm('您还未登录, 是否前往继续?', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   router.replace('/login')
      // })
      return null;
    case 100:
      Notification({
        title: '系统提示',
        message: config.data.message,
        type: 'error'
      })
      return null
  }


  return config
}, (error) => {
  console.log(error)
  if (error.response) {
    
    let errorMessage = error.response.data === null ? '系统内部异常，请联系网站管理员' : error.response.data.message
    switch (error.response.status) {
      
      case 404:
        Notification({
          title: '系统提示',
          message: '很抱歉，资源未找到',
          type: 'error'
        })
        break
      case 403:
      case 10003:
        Notification({
          title: '系统提示',
          message: '登录过期，请重新登录！',
          type: 'error'
        })
        break
      default:
        Notification({
          title: '系统提示',
          message: errorMessage,
          type: 'warning'
        })
        break
    }
  }
  // return Promise.reject(error)
  // return error

})
const request = {
  post (url, params) {
    return REQUEST.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  put (url, params) {
    return REQUEST.put(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  get (url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (let key in params) {
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return REQUEST.get(`${url}${_params}`)
  },
  delete (url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (let key in params) {
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return REQUEST.delete(`${url}${_params}`)
  }
}

export default service