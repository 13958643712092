<template>
  <div>
    <el-form
      ref="detailedFormRef"
      :model="detailedForm"
      class="detailed_form_list"
    >
      <!-- 含税单价-->
      <el-form-item
        class="detailedForm_price"
        prop="provider_money"
        :rules="[{ required: true, message: '请输入单价', trigger: 'blur' }]"
      >
        <el-input
          v-model="detailedForm.provider_money"
          oninput="value=value.replace(/[^\d.]/g,'')"
        ></el-input>
      </el-form-item>
      <!-- 供应数量 -->
      <el-form-item
        class="detailedForm_number"
        prop="provider_number"
        :rules="[{ required: true, message: '请输入数量', trigger: 'blur' }]"
      >
        <el-input
          disabled  
          v-model="detailedForm.provider_number"
          oninput="value=value.replace(/[^\d.]/g,'')"
        ></el-input>
      </el-form-item>
      <!-- 税点-->
      <el-form-item class="detailedForm_tax">
        <el-input
          disabled
          v-model="detailedForm.tax_point"
          oninput="value=value.replace(/[^\d.]/g,'')"
        >
        </el-input>
      </el-form-item>
      <!-- 不含税单价 -->
      <el-form-item class="detailedForm_provider_money">
        <el-input v-model="no_tax_money" disabled></el-input>
      </el-form-item>
      <!-- 不含税合价 -->
      <el-form-item class="detailedForm_no_provider">
        <el-input v-model="totalPrice" disabled></el-input>
      </el-form-item>
      <!-- 含税合价 -->
      <el-form-item class="detailedForm_no_provider">
        <el-input v-model="tax_totalPrice" disabled></el-input>
      </el-form-item>
      <!-- 供货地址 -->
      <el-form-item class="detailedForm_address">
        <el-input v-model="detailedForm.provider_address"></el-input>
      </el-form-item>
      <!-- 其他说明 -->
      <el-form-item class="detailedForm_address">
        <el-input v-model="detailedForm.remark"></el-input>
      </el-form-item>
      <!-- 货品图片 -->
      <el-form-item class="detailedForm_img">
        <p @click="handleImg">货品图片</p>
      </el-form-item>
      <el-form-item class="detailedForm_img">
        <p class="detailedForm_btn" @click="pushQuoted">立即报价</p>
      </el-form-item>
    </el-form>

    <DialogImg
      :showDialog.sync="showDialog"
      @images_path="images_path"
      :detailedForm="detailedForm"
      @handleClose="handleClose"
    ></DialogImg>
  </div>
</template>
<script>
import DialogImg from "../components/DialogImg";
import { Loading } from "element-ui";
import store from "../store";
export default {
  components: {
    DialogImg,
  },
  props: {
    purchase_id: String,
    purchase_detail_id: Number,
    detailedList: Array,
    cancelShow: Boolean,
    orderId: Number,
    amount:Number,
    tax_point:String
  },
  computed: {
    // 不含税单价
    no_tax_money() {
      if (this.detailedForm.provider_money) {
        return (
          this.detailedForm.provider_money / (1 + (this.detailedForm.tax_point / 100))
        ).toFixed(2);
      }
    },
    // 不含税合价
    totalPrice() {
      if (this.no_tax_money && this.detailedForm.provider_number) {
        return (
          this.no_tax_money * this.detailedForm.provider_number
        ).toFixed(2);
      }
    },
    // 含税合价
    tax_totalPrice() {
      if (this.detailedForm.provider_money && this.detailedForm.provider_number) {
        return (this.detailedForm.provider_money * this.detailedForm.provider_number).toFixed(
          2
        );
      }
    },
  },
  data() {
    return {
      detailedForm: {
        tax_point: 0,
      },
      showDialog: false,
      repeatQuoted: false,
    };
  },
  created() {
    if (this.cancelShow) {
      this.getQuoted();
    }
    this.detailedForm.provider_number = this.amount
    this.detailedForm.tax_point = parseFloat(this.tax_point)
    
  },
  methods: {
    // 组件返回的图片信息
    images_path(e) {
      this.detailedForm.images_path = JSON.stringify(e);
    },
    async getQuoted() {
      let { data: res } = await this.$http.get(
        `quoted/show?order_id=${this.orderId}`
      );
      this.detailedForm = res.data;
      // this.detailedForm.images_path = JSON.stringify(res.data.images_path);
      // console.log(this.detailedForm);
    },
    pushQuoted() {
      let token = localStorage.getItem("TOKEN");
      this.$refs.detailedFormRef.validate((vaild) => {
        if (!vaild) return;
        if (!token) return this.$message.warning("请先登录 ！");
        if (this.repeatQuoted) {
          this.$confirm("您已上传过此报价，是否重新上传？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.postDetailedForm();
          });
          return;
        }
        this.postDetailedForm();
      });
    },
    async postDetailedForm() {
      // console.log(this.purchase_detail_id);
      let loadingInstance = Loading.service();
      setTimeout(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      }, 400);
      let { data: res } = await this.$http.post("quoted/create", {
        // ...this.detailedForm,
        no_tax_money: this.no_tax_money,
        provider_number: this.detailedForm.provider_number,
        tax_point: this.detailedForm.tax_point,
        provider_address: this.detailedForm.provider_address,
        remark: this.detailedForm.remark,
        images_path: this.detailedForm.images_path,
        purchase_detail_id: this.purchase_detail_id,
        purchase_id: this.purchase_id,
        provider_money: this.detailedForm.provider_money,
      });

      if (res.code === 200) {
        this.$message.success("提交成功");
        this.repeatQuoted = true;
        return;
      }
    },
    handleImg() {
      let token = localStorage.getItem("TOKEN");
      if (!token) return this.$message.warning("请先登录 ！");
      this.showDialog = true;
    },

    handleClose(){
      this.showDialog = false
    }
  },
};
</script>
<style scoped>
.detailed_form_list {
  padding: 10px 0;
  display: flex;
}
.detailedForm_price {
  width: 80px;
  margin-left: 10px;
  margin-right: 36px;
}
.detailedForm_number {
  width: 76px;
  margin-right: 26px;
}
.detailedForm_tax {
  width: 66px;
  margin-right: 20px;
}
.detailedForm_provider_money {
  width: 80px;
  margin-right: 30px;
}
.detailedForm_no_provider {
  width: 90px;
  margin-right: 26px;
}
.detailedForm_address {
  width: 130px;
  margin-right: 18px;
}
.detailedForm_img {
  margin-left: 20px;
  cursor: pointer;
  color: #f97d03;
}
.detailedForm_btn {
  background: #44b95a;
  color: #fff;
  display: inline-block;
  padding: 0px 10px;
  border-radius: 4px;
}
</style>