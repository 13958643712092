<template>
  <div>
    <UserHead></UserHead>
    <!-- banner -->
    <div class="banner">
      <div>banner部分</div>
    </div>
    <!-- 内容部分 -->
    <div class="settle_box">
      <div class="detailed_box_info">
        <p class="detailed_box_info_title">我的报价单</p>
      </div>
      <div class="supply_box">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-input
              placeholder="请输入内容"
              v-model="query.purchase_name"
              clearable
              @clear="getQuoteList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getQuoteList"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
        <div class="supply_table">
          <el-table :data="quoteList" stripe style="width: 100%" border>
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column
              prop="purchase.purchase_name"
              label="采购单名称"
              width="220"
            >
            </el-table-column>
            <el-table-column
              prop="purchase.category.purchase_name"
              label="类型"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="purchase.company_name"
              label="发布单位"
              width="240"
            >
            </el-table-column>
            <el-table-column prop="purchase.deadline" label="截止时间">
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <span>{{
                  scope.row.purchase.purchase_status === 1
                    ? "报价中"
                    : "报价结束"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="320">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.purchase.purchase_status === 1"
                  type="primary"
                  size="mini"
                  @click="editSupply(scope.row.id)"
                  >上传报价</el-button
                >
                <el-button
                  v-if="scope.row.purchase.purchase_status === 1"
                  type="danger"
                  size="mini"
                  @click="cancelQuote(scope.row.purchase_id)"
                  >取消报价</el-button
                >
                <el-button
                  size="mini"
                  @click="goSupplyDetail(scope.row.purchase.id)"
                  >报价详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="query.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="detailedListTotal"
            :hide-on-single-page="hideSinglePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <FooterInfo></FooterInfo>
  </div>
</template>
<script>
import UserHead from "../components/UserHeader.vue";
import FooterInfo from "../components/Footer";

import { Loading } from "element-ui";
export default {
  components: {
    UserHead,
    FooterInfo,
  },
  data() {
    return {
      query: {
        page: 1,
        size: 10,
        purchase_name: "",
      },
      quoteList: [],
      detailedListTotal: 0,
      hideSinglePage: true,
    };
  },
  created() {
    this.getQuoteList();
  },
  methods: {
    async getQuoteList() {
      let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("quoted/lists", {
        params: { ...this.query },
      });
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
      if (res.code !== 200)
        return this.$message.error("列表获取失败，请稍后重试");
      this.quoteList = res.data.list;
      this.detailedListTotal = res.data.count;
    },
    // 分页
    handleSizeChange(val) {
      this.query.size = val;
      this.getQuoteList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getQuoteList();
    },
    cancelQuote(id) {
      console.log(id);
      this.$confirm("是否取消所有报价信息", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteQuote(id);
        })
        .catch(() => {});
    },
    async deleteQuote(id) {
      let { data: res } = await this.$http.put("quoted/status", {
        purchase_id: id,
      });
      if (res.code !== 200)
        return this.$message.error("取消报价失败，请稍后再试");
      this.$message.success("取消报价成功");
      this.getQuoteList();
    },
    // 前往详情
    goSupplyDetail(id) {
      this.$router.push({
        path: "/purchaseDetail",
        query: {
          id: id,
          cancelShow: true,
        },
      });
    },
  },
};
</script>
<style scoped>
.banner {
  width: 1200px;
  height: 240px;
  line-height: 240px;
  text-align: center;
  margin: 20px auto;
  background: #d8d8d8;
}

.settle_box {
  width: 100%;
  background: #fff;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
}

.supply_box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 40px;
}
.supply_table {
  margin-top: 30px;
}

.pagination {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
</style>