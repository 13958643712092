<template>
  <div>
    <!-- 头部状态条 -->
    <HeaderInfo></HeaderInfo>

    <div>
      <div class="purchase_info">
        <p class="name">{{noticeDeatil.name}}</p>
        <p class="time">{{noticeDeatil.create_time}}</p>
        <p v-html="noticeDeatil.content"></p>
      </div>
    </div>

    <FooterInfo></FooterInfo>
  </div>
</template>

<script>
export default {
  data(){
    return{
      id:'',
      noticeDeatil:{}
    }
  },
  created(){
    this.id = this.$route.query.id
    this.getNoticeDeatil()
  },
  methods:{
    async getNoticeDeatil() {
      const { data: res } = await this.$http.get("v1/common/message/find", {
        params: {id:this.id},
      });
      this.noticeDeatil = res.data;
    },
  }
}
</script>

<style scope>
.purchase_info {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 20px;
}
.name{
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}
.time{
  color: #999;
  margin-bottom: 10px;

}
</style>