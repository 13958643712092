<template>
  <div>
    <el-dialog
      title="添加供应信息"
      :visible="addSupplyDialog"
      width="50%"
      :before-close="handleClose"
      top="15vh"
    >
      <el-form ref="supplyFormRef" :model="supplyForm" label-width="80px">
        <el-form-item
          label="名称"
          prop="name"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <el-input v-model="supplyForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item
          label="类型"
          prop="purchase_category_id"
          :rules="[{ required: true, message: '请选择类型', trigger: 'blur' }]"
        >
          <el-cascader
            placeholder="请选择类型"
            v-model="supplyForm.purchase_category_id"
            :options="suppleOptions"
            :props="suppleProps"
            filterable
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细描述">
          <el-input type="textarea" v-model="supplyForm.remark" placeholder="请输入详细描述"></el-input>
        </el-form-item>
        <el-form-item label="相关附件">
          <el-upload
            :action="domain"
            :data="qiNiuData"
            class="upload-idCard"
            list-type="picture-card"
            :file-list="defaultImg"
            ref="imgRef"
            :on-preview="handleShow"
            :on-error="handleError"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :before-upload="beforeAvatarUpload"
            :limit="6"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="isShowImg" width="50%" size="tiny">
            <img width="100%" :src="myFileUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    addSupplyDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      supplyForm: {},
      suppleSelect: [],
      suppleOptions: [],
      suppleProps: {
        expandTrigger: "hover",
        value: "purchase_id",
        label: "purchase_name",
        children: "children",
      },
      domain: "https://upload-z2.qiniup.com",
      BASE_URL: "https://gylian.yiputouzi.com/",
      qiNiuData: {},
      defaultImg: [],
      myFileUrl: "",
      images_path: [],
      isShowImg: false,
    };
  },
  created() {
    this.getSuppleType();
    this.getQiNiuToken();
  },
  methods: {
    handleClose() {
      this.$refs.supplyFormRef.resetFields()
      this.supplyForm = {}
      this.defaultImg = [];
      this.images_path = [];
      this.$emit("update:addSupplyDialog", false);
    },
    async getQiNiuToken() {
      let { data: res } = await this.$http.get("v1/common/qiniu/token");
      this.qiNiuData.token = res.data.token;
    },
    async getSuppleType() {
      let { data: res } = await this.$http.post("v1/user/ident/type");
      // let { data: res } = await this.$http.get("v1/common/supplier/type");
      this.suppleOptions = res;
    },

    // 上传图片

    // 上传文件时的钩子函数
    beforeAvatarUpload(file) {
      // console.log("上传文件时的钩子函数");
      return this.setFileValidation(file);
    },
    // 文件验证
    setFileValidation(file) {
      this.qiNiuData.key = file.name;
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      let isJPG = (type == "jpg" || type == "png" || type == "gif") && true;
      !isJPG &&
        this.$message.error("上传的文件不符合规范!,支持格式:png/jpg/gif");
      return isJPG;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多只能上传6张图片，请删除后再添加`);
    },
    // 选择时触发
    handleChange(file) {
      // console.log("选择时触发", file);
      // this.hideUpload = this.defaultImg.length >= this.limitCount;
    },
    // 成功之后判断类型
    isSuccessFileType(key) {
      // console.log("成功之后判断类型");
      // let arr = key.split(".");
      // return arr[arr.length - 1];
    },
    // 返回错误
    handleError() {
      Message({
        message: "文件上传失败",
        type: "error",
        duration: 5 * 1000,
      });
    },

    // 成功返回
    handleAvatarSuccess(res, file) {
      // console.log("成功返回", res);
      this.myFileUrl = this.BASE_URL + res.hash;
      let img = { hash: res.hash, url: this.myFileUrl };
      this.images_path.push(img);
    },

    handleRemove(file, fileList) {
      // console.log("删除", file, fileList);
      if (fileList.length > 0) {
        let imgUrl = {};
        this.images_path = [];
        fileList.forEach((item) => {
          imgUrl = {
            hash: item.response.hash,
            url: this.BASE_URL + item.response.hash,
          };
          this.images_path.push(imgUrl);
        });
      } else {
        this.images_path = [];
      }
      // console.log(this.images_path);
    },
    // 点击文件列表中已上传的文件时的钩子
    async handleShow(file) {
      this.myFileUrl = file.url;
      this.isShowImg = true;
      // this.myFileUrl = this.BASE_URL + this.myHashUrl;
      // this.isType && (await this.openFile());
    },

    save() {
      this.$refs.supplyFormRef.validate(async (vaild) => {
        if (!vaild) return false;
        this.supplyForm.purchase_category_id = this.supplyForm.purchase_category_id[1]
        this.supplyForm.images = JSON.stringify(this.images_path)

        let { data: res } = await this.$http.post('v1/user/business/create',{...this.supplyForm});
        if(res.code === 200){
          this.$refs.supplyFormRef.resetFields()
          this.supplyForm = {}
          this.defaultImg = [];
          this.images_path = [];
          this.$message.success("创建成功");
          this.$emit('success')
        }
      })
    },
  },
};
</script>
<style scoped>
.el-cascader {
  width: 100%;
}
</style>