<template>
  <div>
    <UserHead></UserHead>
    <!-- banner -->
    <!-- <div class="banner"></div> -->
    <div>
      <div class="purchase_info">
        <div style="margin-bottom: 20px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/purchase' }"
              >采购清单
            </el-breadcrumb-item>
            <el-breadcrumb-item>采购单详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="detailed_box_info">
          <p class="detailed_box_info_title">基本信息</p>
        </div>
        <!-- 详情 -->
        <div class="purchase_detail">
          <div class="purchase_detail_banner">
            <!-- <img src="" alt="" /> -->
            <el-carousel :interval="3000" :arrow="arrow">
              <el-carousel-item
                v-for="(img, index) in purchaseForm.banner"
                :key="index"
              >
                <img v-bind:src="img" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="purchase_info_detail">
            <div class="purchase_title">
              <div class="purchase_title">
                <p class="purchase_name">{{ purchaseForm.purchase_name }}</p>
                <p class="purchase_label">
                  {{
                    purchaseForm.purchase_status === 1 ? "报价中" : "报价结束"
                  }}
                </p>
              </div>
              <div
                class="purchase_count"
                @click="editPurchase(purchaseForm.id, purchaseForm.is_status)"
              >
                修 改
              </div>
              <!-- <p class="purchase_count">
                采购 {{ purchaseForm.purchase_count }} 件
              </p> -->
            </div>
            <p class="deadline">截止时间：{{ purchaseForm.deadline }}</p>
            <!-- <p class="deadline">
              本次交易支持融资申请 （<span
                class="purchase_financing"
                @click="financingDialogVisible = true"
                >了解详情</span
              >）
              <span class="financing_btn" @click="financingVisible = true"
                >融资申请</span
              >
            </p> -->
            <p class="purchase_demand">采购要求</p>
            <div class="purchase_demand_info">
              <p>
                <span>发布单位：{{ purchaseForm.company_name }}</span>
                <span>
                  联系方式：{{ purchaseForm.contact_name }}
                  {{ purchaseForm.contact_phone }}
                </span>
              </p>
              <p>
                <span>用材地址：{{ purchaseForm.address }}</span>
                <span>进场车限制：{{ purchaseForm.car_limit }}</span>
              </p>
              <p>
                <span>进场日期：{{ purchaseForm.enter_limit }}</span>
                <span>报价要求：{{ quote_priceName }}</span>
              </p>
              <p>
                <span>发票要求：{{ invoiceName }}</span>
                <span>含税要求：{{ taxName }}</span>
                <span>税点：{{ purchaseForm.tax_point }}</span>
              </p>
              <p>
                其他说明：{{ purchaseForm.other ? purchaseForm.other : "无" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 内容部分 -->
    <div class="settle_box">
      <div class="detailed_box_info">
        <p class="detailed_box_info_title">清单明细</p>
      </div>
      <div class="supply_box">
        <p class="purchaseName">{{ purchaseName }}</p>
        <el-table :data="quoteList" stripe style="width: 100%" border>
          <el-table-column type="index" label="#"> </el-table-column>
          <el-table-column prop="name" label="清单名称"> </el-table-column>
          <el-table-column prop="amount" label="数量"> </el-table-column>

          <el-table-column prop="unit" label="单位"> </el-table-column>

          <el-table-column prop="spec_remark" label="规格信息">
          </el-table-column>
          <el-table-column prop="other" label="详细描述"> </el-table-column>
          <el-table-column label="图片" width="260px">
            <template slot-scope="scope">
              <div style="display: flex; flex-wrap: wrap">
                <div
                  v-for="(item, index) in scope.row.images_path"
                  :key="index"
                >
                  <img
                    style="width: 100px; height: auto; margin-right: 10px"
                    :src="item.url"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80px">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.purchase_status"
                @change="changeSwitch(scope.row)"
                :active-value="active_value"
                :inactive-value="inactive_value"
                active-color="#13ce66"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="editInfo(scope.row)"
                >修改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="query.page"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="detailedListTotal"
          :hide-on-single-page="hideSinglePage"
        >
        </el-pagination>
      </div>
    </div>
    <FooterInfo></FooterInfo>

    <EditDetiled
      :quoteDialogVisible.sync="quoteDialogVisible"
      :DetailedList.sync="DetailedList"
      @update="getQuoteList"
    ></EditDetiled>
  </div>
</template>
<script>
import UserHead from "../components/UserHeader.vue";
import FooterInfo from "../components/Footer";
import EditDetiled from "../components/EditDetiled";
import UploadData from "../components/UploadData";

import { Loading } from "element-ui";
export default {
  components: {
    UserHead,
    FooterInfo,
    EditDetiled,
    UploadData,
  },
  data() {
    return {
      query: {
        page: 1,
        size: 10,
        purchase_id: "",
      },
      purchaseName: "",
      purchaseForm: {},
      quoteList: [],
      BASE_URL: "https://gylian.yiputouzi.com/",
      detailedListTotal: 0,
      quoteDialogVisible: false,
      DetailedList: {},
      active_value: 1,
      inactive_value: 2,
      detailedListTotal: 0,
      hideSinglePage: true,
      arrow: "never",
      quote_priceName: "",
      invoiceName: "",
      taxName: "",
    };
  },
  created() {
    this.query.purchase_id = this.$route.query.id;
    this.getPurchaseForm();
    this.getQuoteList();
  },
  filters: {
    tofixed(value) {
      return value.tofixed(2);
    },
  },
  methods: {
    async getPurchaseForm() {
      let { data: res } = await this.$http.get(
        `purchase/detail?purchase_id=${this.query.purchase_id}`
      );

      if (res.code !== 200)
        return this.$message.error("信息获取失败，请稍后再试");
      this.purchaseForm = res.data;

      if (this.purchaseForm.banner > 1) {
        this.arrow = "always";
      }
      this.quote_priceName = res.data.quote_price.name;
      this.invoiceName = res.data.invoice.name;
      if (res.data.tax) {
        this.taxName = res.data.tax.name;
      } else {
        this.taxName = "不含税";
      }
    },
    // 修改采购单
    editPurchase(id, status) {
      if (status === 2) return this.$message.error("请上架后再修改采购单！");
      this.$router.push({
        path: "/addPurchase",
        query: {
          id: id,
        },
      });
    },
    async getQuoteList() {
      let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("purchase/other/list", {
        params: this.query,
      });
      this.$nextTick(() => {
        loadingInstance.close();
      });
      if (res.code !== 200)
        return this.$message.error("清单列表获取失败，请稍后重试");
      this.quoteList = res.data.list;
      this.purchaseName = this.quoteList[0].purchase.purchase_name;
      this.detailedListTotal = res.data.count;
    },
    // 分页
    handleSizeChange(val) {
      this.query.size = val;
      this.getQuoteList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getQuoteList();
    },
    // 切换状态
    async changeSwitch(row) {
      let { data: res } = await this.$http.put(
        `purchase/other/status?id=${row.id}&purchase_status=${row.purchase_status}`
      );
      if (res.code !== 200)
        return this.$message.error("状态切换失败，请稍后重试");
      this.$message.success("状态切换成功");
      this.getQuoteList();
    },
    async editInfo(row) {
      if (row.purchase_status === 2) {
        return this.$message.error("请先上架后再修改");
      }
      this.DetailedList = row;

      this.quoteDialogVisible = true;
    },
  },
};
</script>
<style scoped>
.purchase_info {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 40px;
}
.purchase_detail {
  display: flex;
  padding-bottom: 20px;
}
.purchase_detail_banner {
  width: 280px;
  height: 280px;
}
.purchase_info_detail {
  margin-left: 20px;
  width: 900px;
}
.purchase_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.purchase_name {
  font-size: 26px;
  color: #333;
}
.purchase_label {
  font-size: 17px;
  background: #20ab3a;
  color: #fff;
  padding: 2px 5px;
  margin-left: 10px;
}
.purchase_count {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 88px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;

  /* font-size: 22px;
  color: #dc5b56; */
}
.deadline {
  color: #333;
  font-size: 16px;
  margin-top: 10px;
}
.purchase_financing {
  color: #20ab3a;
  cursor: pointer;
}
.financing_btn {
  color: #f97d03;
  cursor: pointer;
  margin-left: 10px;
}
.purchase_demand {
  margin-top: 30px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.purchase_demand_info {
  margin-top: 15px;
}
.purchase_demand_info > p {
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}
.purchase_demand_info > p > span {
  margin-right: 20px;
}

.banner {
  width: 1200px;
  height: 240px;
  line-height: 240px;
  text-align: center;
  margin: 20px auto;
  background: #d8d8d8;
}

.settle_box {
  width: 100%;
  background: #fff;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
  /* margin-top: 20px; */
}

.supply_box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
.info_title {
  margin-top: 30px;
  color: #333;
  font-size: 22px;
}
.supply_table {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.supply_table > .el-form-item {
  margin-right: 100px;
  width: 500px;
}
.itemImg {
  width: 100% !important;
}
.save_btn {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 120px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 4px;
}
.purchaseName {
  font-size: 20px;
  font-weight: bold;
  color: #20ab3a;
  margin-bottom: 10px;
}

.el-cascader {
  width: 100%;
}
.pagination {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
}
</style>