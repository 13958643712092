<template>
  <div>
    <el-upload
      :action="domain"
      :data="qiNiuData"
      class="upload-idCard"
      list-type="picture-card"
      :file-list="defaultImg"
      ref="imgRef"
      :on-preview="handleShow"
      :on-error="handleError"
      :on-success="handleAvatarSuccess"
      :on-remove="handleRemove"
      :on-exceed="handleExceed"
      :before-upload="beforeAvatarUpload"
      :limit="6"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="isShowImg" width="50%" size="tiny">
      <img width="100%" :src="myFileUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    purchaseForm: {
      type: Object,
    },
    echoData: {
      type: Boolean,
    },
  },
  data() {
    return {
      domain: "https://upload-z2.qiniup.com",
      BASE_URL: "https://gylian.yiputouzi.com/",
      qiNiuData: {},
      defaultImg: [],
      myFileUrl: "",
      images_path: [],
      isShowImg: false,
    };
  },
  created() {
    this.getQiNiuToken();
  },
  watch: {
    echoData() {
      if (this.purchaseForm.images_path) {
        this.purchaseForm.images_path.forEach((item) => {
          let obj = new Object();
          obj.response = {};
          let img_path = {};
          obj.response.hash = item;
          obj.url = this.BASE_URL + item;
          img_path.hash = item;
          img_path.url = this.BASE_URL + item;
          this.images_path.push(img_path);
          this.defaultImg.push(obj);
        });
        this.$emit("images_path", this.images_path);
        // console.log(this.defaultImg);
      }
    },
  },
  methods: {
    async getQiNiuToken() {
      let { data: res } = await this.$http.get("v1/common/qiniu/token");
      this.qiNiuData.token = res.data.token;
    },
    // 上传图片

    // 上传文件时的钩子函数
    beforeAvatarUpload(file) {
      // console.log("上传文件时的钩子函数");
      return this.setFileValidation(file);
    },
    // 文件验证
    setFileValidation(file) {
      this.qiNiuData.key = file.name;
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      let isJPG = (type == "jpg" || type == "png" || type == "gif") && true;
      !isJPG &&
        this.$message.error("上传的文件不符合规范!,支持格式:png/jpg/gif");
      return isJPG;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多只能上传6张图片，请删除后再添加`);
    },
    // 选择时触发
    handleChange(file) {
      // console.log("选择时触发", file);
      // this.hideUpload = this.defaultImg.length >= this.limitCount;
    },
    // 成功之后判断类型
    isSuccessFileType(key) {
      // console.log("成功之后判断类型");
      // let arr = key.split(".");
      // return arr[arr.length - 1];
    },
    // 返回错误
    handleError() {
      Message({
        message: "文件上传失败",
        type: "error",
        duration: 5 * 1000,
      });
    },

    // 成功返回
    handleAvatarSuccess(res, file) {
      // console.log("成功返回", res);
      this.myFileUrl = this.BASE_URL + res.hash;
      let img = { hash: res.hash, url: this.myFileUrl };
      this.images_path.push(img);
      this.$emit("images_path", this.images_path);
    },

    handleRemove(file, fileList) {
      if (fileList.length > 0) {
        let imgUrl = {};
        this.images_path = [];
        fileList.forEach((item) => {
          imgUrl = {
            hash: item.response.hash,
            url: this.BASE_URL + item.response.hash,
          };
          this.images_path.push(imgUrl);
          this.$emit("images_path", this.images_path);
        });
      } else {
        this.images_path = [];
        this.$emit("images_path", this.images_path);
      }
      // console.log(this.images_path);
    },
    // 点击文件列表中已上传的文件时的钩子
    async handleShow(file) {
      this.myFileUrl = file.url;
      this.isShowImg = true;
      // this.myFileUrl = this.BASE_URL + this.myHashUrl;
      // this.isType && (await this.openFile());
    },
  },
};
</script>
<style scoped>
</style>