import Vue from 'vue'
import VueRouter from 'vue-router'
import db from '../utils/localstorage'
import store from '../store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import UserInfo from '../views/UserInfo.vue'
import PurchaseDetail from '../views/PurchaseDetail.vue'
import SupplierDetail from '../views/SupplierDetail.vue'
import Supply from '../views/Supply.vue'
import Quote from '../views/Quote.vue'
import Purchase from '../views/Purchase.vue'
import AddPurchase from '../views/AddPurchase.vue'
import PurchaseQuoteList from '../views/PurchaseQuoteList.vue'
import FinanceDetail from '../views/FinanceDetail.vue'
import PurchaseList from '../views/PurchaseList.vue'
import SupplyList from '../views/SupplyList.vue'
import PurchaseDetailedList from '../views/PurchaseDetailedList.vue'
import TeamList from '../views/TeamList.vue'
import TeamDetail from '../views/TeamDetail.vue'
import NoticeDeatil from '../views/NoticeDeatil.vue'







Vue.use(VueRouter)


const routes = [
  {path:'/',redirect:{name:"Home"}},  // 重定向到主页
  {path: '/home',name: 'Home',component: Home},
  {path:'/login',name:'Login',component:Login},
  {path:'/userInfo',name:'UserInfo',component:UserInfo},
  {path:'/purchaseDetail',name:'PurchaseDetail',component:PurchaseDetail},
  {path:'/supplierDetail',name:'SupplierDetail',component:SupplierDetail},
  {path:'/supply',name:'Supply',component:Supply},
  {path:'/quote',name:'Quote',component:Quote},
  {path:'/purchase',name:'Purchase',component:Purchase},
  {path:'/addPurchase',name:'AddPurchase',component:AddPurchase},
  {path:'/purchaseQuoteList',name:'PurchaseQuoteList',component:PurchaseQuoteList},
  {path:'/financeDetail',name:'FinanceDetail',component:FinanceDetail},
  {path:'/purchaseList',name:'PurchaseList',component:PurchaseList},
  {path:'/supplyList',name:'SupplyList',component:SupplyList},
  {path:'/purchaseDetailedList',name:'PurchaseDetailedList',component:PurchaseDetailedList},
  {path:'/teamList',name:'TeamList',component:TeamList},
  {path:'/teamDetail',name:'TeamDetail',component:TeamDetail},
  {path:'/noticeDeatil',name:'NoticeDeatil',component:NoticeDeatil},
  
  
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

// 解决路由重复问题
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}


//挂载路由导航守卫
router.beforeEach((to,from,next)=>{
  // if(to.query.code){
  //   alert(to.query.code,to.query.state)
  //   return next('/purchase')
  //   router.app.$options.store.dispatch('getCode',
  //     to.query.code,
  //     to.query.state
  //   )
  // }
  //to 将要访问的路径
  //from 代表从哪个路径跳转而来
  //next 是一个函数。表示放行
    //  next()  放行   next('/login)  强制跳转
  document.title = '供应链'
  if(to.path === '/login' || to.path === '/home' || to.path === '/noticeDeatil') return next()

  if(to.path === '/purchaseDetail' || to.path === '/supplierDetail' || to.path === '/teamList' || to.path === '/teamDetail') return next()


  //获取token 验证
  const tokenStr = localStorage.getItem('TOKEN')
  // const tokenStr = store.state.account.loginToken
  // console.log(tokenStr,1)
  
  //没有token将强制跳转至登陆页面
  if(!tokenStr) return next('/login')
  next()
})  

export default router
