export default {
  namespaced: true,
  state: {
    sidebar: {
      opened: true
    },
    settingBar: {
      opened: false
    },
    //  系统参数配置
    systemName: '园林惠内部管理系统',
    copyright: `${new Date().getFullYear()} <a href="#" target="_blank"></a>`,
  },
  mutations: {
  }
}
