<template>
  <div>
    <!-- 头部状态条 -->
    <HeaderInfo></HeaderInfo>
    <!-- 内容部分 -->
    <div class="content">
      <div class="content_search">
        <el-form ref="purchaseQueryRef" :model="query" label-width="100px">
          <el-form-item label="供应商名称:">
            <el-input v-model="query.name"></el-input>
          </el-form-item>
          <el-form-item label="筛选:">
            <el-cascader
              placeholder="请选择类型"
              v-model="suppleSelect"
              :options="suppleOptions"
              :props="suppleProps"
              @change="changeSelect"
              filterable
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item>
            <div class="btn">
              <div class="search" @click="search">查 询</div>
              <el-button @click="reset">重 置</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表单内容 -->
      <div class="content_box">
        <div
          class="list_content"
          v-for="(item, index) in supplyList"
          :key="index"
          @click="goSupplier(item.id)"
        >
          <div class="purchase_list_info">
            <div class="list_img">
              <img :src="item.cover" alt="" />
            </div>
            <div class="supple_list">
              <div class="supple_title_box">
                <p class="supple_title">{{ item.company_name }}</p>
                <p class="purchase_statu">10条供应</p>
              </div>
              <p class="supple_list_info">
                联系电话：{{ item.contact_name }} {{ item.contact_phone }}
              </p>
              <p class="supple_list_info">公司地址：{{ item.address }}</p>
              <p class="supple_list_info">主营内容：{{ item.business_info }}</p>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="query.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="detailedListTotal"
            :hide-on-single-page="hideSinglePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <FooterInfo></FooterInfo>
  </div>
</template>
<script>

import { Loading } from "element-ui";
export default {
  components: {

  },
  data() {
    return {
      supplyList: [],
      query: {
        page: 1,
        size: 10,
        name: "",
        type: "",
      },
      suppleSelect: [],
      suppleOptions: [],
      suppleProps: {
        expandTrigger: "hover",
        value: "purchase_id",
        label: "purchase_name",
        children: "children",
      },
      detailedListTotal: 0,
      hideSinglePage: true,
    };
  },
  created() {
    this.getSuppleType();
    this.getSupplierList();
  },
  methods: {
    async getSuppleType() {
      let { data: res } = await this.$http.post("v1/user/ident/type");
      // let { data: res } = await this.$http.get("v1/common/supplier/type");
      this.suppleOptions = res;
    },
    changeSelect() {
      // console.log(this.suppleSelect[1]);
      this.query.type = this.suppleSelect[1];
    },
    search() {
      this.getSupplierList();
    },
    reset() {
      this.query.type = "";
      this.query.name = "";
      this.suppleSelect = [];
      this.getSupplierList();
    },

    // 获取供应商列表
    async getSupplierList() {
      let loadingInstance = Loading.service();
      const { data: res } = await this.$http.get("v1/supplier/list", {
        params: this.query,
      });
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
      this.supplyList = res.data.list;
      this.detailedListTotal = res.data.count;
    },
    // 跳转供应商列表
    goSupplier(id) {
      // console.log("跳转供应商详情" + id);
      this.$router.push({
        path: "/supplierDetail",
        query: {
          id: id,
        },
      });
    },
    // 分页
    handleSizeChange(val) {
      this.query.size = val;
      this.getSupplierList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getSupplierList();
    },
  },
};
</script>
<style scoped>
.content {
  width: 1200px;
  margin: 20px auto;
  background: #fff;
  border-radius: 4px;
  padding-top: 20px;
}
.content_search {
  border-bottom: 1px solid #eee;
}
.content_box {
}
.el-form {
  display: flex;
  flex-wrap: wrap;
}
.el-form-item {
  width: 450px;
}
.el-cascader {
  width: 100%;
}

.search {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 80px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 20px;
}
.btn {
  display: flex;
}

.list_content {
  padding: 20px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}
.purchase_list_info {
  display: flex;
}
.list_content {
  padding: 20px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}
.list_img {
  width: 130px;
  height: 130px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.img_category {
  position: absolute;
  top: 0;
  left: 0;
  background: #20ab3a;
  color: #fff;
  font-size: 13px;
  padding: 2px 5px 2px 5px;
}
.purchase_list_right {
  margin-left: 20px;
  width: 1010px;
}
.purchase_name_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.purchase_name {
  font-size: 22px;
  color: #333;
}
.purchase_statu {
  color: #20ab3a;
  font-size: 20px;
  font-weight: 500;
  text-align: right;
}
.secondary_color {
  color: #dc5b56;
}
.purchase_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.purchase_label_left {
  font-size: 13px;
  display: flex;
}
.purchase_label_left > p {
  margin-right: 10px;
  padding: 2px 8px 2px 8px;
  background: #20ab3a;
  color: #fff;
}
.purchase_time {
  color: #333;
  font-size: 16px;
}
.purchase_address {
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}
.purchase_count {
  color: #dc5b56;
  font-size: 20px;
}
.purchase_btn_info {
  background: #20ab3a;
  border-radius: 5px;
  padding: 5px 12px;
  color: #fff;
  font-size: 15px;
}
/* 供应商列表 */
.supple_list {
  /* display: flex; */
  margin-left: 20px;
  width: 1010px;
}
.supple_title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.supple_title {
  color: #333;
  font-size: 22px;
}
.supple_list_info {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.pagination {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}
</style>