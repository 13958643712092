var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"报价详情","visible":_vm.quoteDialogVisible,"width":"50%","top":"15vh","before-close":_vm.handleClose}},[_c('el-form',{ref:"itemFormRef",attrs:{"model":_vm.item,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"名称","prop":"name","rules":[
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur',
          } ]}},[_c('el-input',{model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('el-form-item',{attrs:{"label":"数量","prop":"amount","rules":[
          {
            required: true,
            message: '请输入数量',
            trigger: 'blur',
          } ]}},[_c('el-input',{model:{value:(_vm.item.amount),callback:function ($$v) {_vm.$set(_vm.item, "amount", $$v)},expression:"item.amount"}})],1),_c('el-form-item',{attrs:{"label":"单位","prop":"unit","rules":[
          {
            required: true,
            message: '请输入单位',
            trigger: 'blur',
          } ]}},[_c('el-input',{model:{value:(_vm.item.unit),callback:function ($$v) {_vm.$set(_vm.item, "unit", $$v)},expression:"item.unit"}})],1),_c('el-form-item',{attrs:{"label":"规格信息"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.item.spec_remark),callback:function ($$v) {_vm.$set(_vm.item, "spec_remark", $$v)},expression:"item.spec_remark"}})],1),_c('el-form-item',{attrs:{"label":"详细描述"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.item.other),callback:function ($$v) {_vm.$set(_vm.item, "other", $$v)},expression:"item.other"}})],1),_c('el-form-item',{attrs:{"label":"相关附件"}},[_c('el-upload',{ref:"imgRef",staticClass:"upload-idCard",attrs:{"action":_vm.domain,"data":_vm.qiNiuData,"list-type":"picture-card","file-list":_vm.defaultImg,"on-preview":_vm.handleShow,"on-error":_vm.handleError,"on-success":_vm.handleAvatarSuccess,"on-remove":_vm.handleRemove,"on-exceed":_vm.handleExceed,"before-upload":_vm.beforeAvatarUpload,"limit":6,"multiple":""}},[_c('i',{staticClass:"el-icon-plus"})]),_c('el-dialog',{attrs:{"visible":_vm.isShowImg,"width":"50%","size":"tiny"},on:{"update:visible":function($event){_vm.isShowImg=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.myFileUrl,"alt":""}})])],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }