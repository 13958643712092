<template>
  <div>
    <el-dialog
      title="批量提交报价"
      :visible="showDialog"
      :before-close="handleClose"
      width="30%"
      top="30vh"
    >
      <el-upload
        :action="domain"
        :data="qiNiuData"
        :before-upload="beforeAvatarUpload"
        :on-success="handleAvatarSuccess"
        :on-remove="handleRemove"
      >
        <el-button type="primary">选择文件</el-button>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitUpload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      domain: "https://upload-z2.qiniup.com",
      qiNiuData: {},
      BASE_URL: "https://gylian.yiputouzi.com/",
      fileName: "",
      files: {},
    };
  },
  created() {
    this.getQiNiuToken();
  },
  methods: {
    handleClose() {
      this.$emit("update:showDialog", false);
      console.log(this.showDialog);
    },
    confirm() {
      this.$emit("update:showDialog", false);
    },
    // 七牛云获取token
    async getQiNiuToken() {
      let { data: res } = await this.$http.get("v1/common/qiniu/token");
      this.qiNiuData.token = res.data.token;
    },
    handleRemove(file, fileList) {
      this.fileName = "";
      this.files = {};
    },
    beforeAvatarUpload(file) {
      return this.setFileValidation(file);
    },
    // 文件验证
    setFileValidation(file) {
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      let isLt2M = file.size / 1024 / 1024 < 5;
      let isJPG = type == "xlsx" && true;
      !isJPG && this.$message.error("文件类型有误，请重新上传,仅支持xlsx文件");
      !isLt2M && this.$message.warning("上传模板大小不能超过 5MB!");
      this.qiNiuData.key = file.name;
      this.fileName = file.name;
      this.files = file;
      return isJPG;
    },
    handleAvatarSuccess(res, file) {
      console.log("xuanze", res, file);
      console.log(this.files);
    },

    // 上传文件
    submitUpload() {
      console.log("上传" + this.files.name);
      if (this.fileName == "") {
        this.$message.warning("请选择要上传的文件！");
        return false;
      }
      let fileFormData = new FormData();
      // fileFormData.append("file", this.files, this.fileName, this.purchase_id); //filename是键，file是值，就是要传的文件，test.zip是要传的文件名
      fileFormData.append("filename", this.BASE_URL + this.files.name);
      fileFormData.append("clientType", "web");

      // let fileFormData = {
      //   formData: {

      //   },
      // };
      let requestConfig = {
        header: {
          token: this.qiNiuData.token,
        },
      };
      this.$http
        .post(
          `api/quoted/import?purchase_id=` + this.id,
          fileFormData,
          requestConfig
        )
        .then((res) => {
          console.log(res);
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
            });
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
};
</script>
<style scoped>
</style>