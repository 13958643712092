<template>
  <div>
    <el-dialog
      title="报价详情"
      :visible="quoteDialogVisible"
      width="80%"
      top="12vh"
      :before-close="handleClose"
    >
      <div>
        <p class="quote_supplier">报价供应商：{{ companyName }}</p>
        <p class="quote_contact">联系方式：{{ contactPhone }}</p>
      </div>
      <div>
        <el-table :data="quoteInfoList" stripe style="width: 100%" border>
          <el-table-column type="index" label="#"></el-table-column>
          <el-table-column
            prop="purchase_detail.name"
            label="名称"
          ></el-table-column>
          <el-table-column
            prop="purchase_detail.amount"
            label="需要数量"
          ></el-table-column>
          <el-table-column
            prop="purchase_detail.unit"
            label="单位"
          ></el-table-column>
          <el-table-column
            prop="provider_number"
            label="供应数量"
          ></el-table-column>
          <el-table-column prop="tax_point" label="税点"></el-table-column>
          <el-table-column
            prop="no_tax_money"
            label="不含税单价"
          ></el-table-column>
          <el-table-column
            prop="provider_money"
            label="含税单价"
          ></el-table-column>
          <el-table-column label="不含税合价">
            <template slot-scope="scope">
              <p>{{ scope.row.no_tax_total_money | tofixed }}</p>
            </template>
          </el-table-column>
          <el-table-column label="含税合价">
            <template slot-scope="scope">
              <p>{{ scope.row.total_money | tofixed }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="provider_address" label="供货地址">
          </el-table-column>
          <el-table-column prop="remark" label="报价备注"></el-table-column>
          <el-table-column label="图片" width="260px">
            <template slot-scope="scope">
              <div style="display: flex; flex-wrap: wrap">
                <div
                  v-for="(item, index) in scope.row.images_path"
                  :key="index"
                >
                  <img
                    style="width: 100px; height: auto; margin-right: 10px"
                    :src="item.url"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="query.page"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="countTotal"
          :hide-on-single-page="hideSinglePage"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Loading } from "element-ui";
export default {
  props: {
    quoteDialogVisible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      query: {
        page: 1,
        size: 5,
      },
      quoteInfoList: [],
      countTotal: 0,
      hideSinglePage: true,
      companyName: "",
      contactPhone: "",
    };
  },
  created() {},
  watch: {
    item() {
      this.query.purchase_id = this.item.purchase_id;
      this.query.user_id = this.item.user_id;

      this.getQuoteInfoList();
    },
  },
  filters: {
    tofixed(value) {
      let tempVal = parseFloat(value).toFixed(2);
      return tempVal;
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:quoteDialogVisible", false);
    },
    async getQuoteInfoList() {
      // let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("purchase/supplier/detail", {
        params: this.query,
      });
      // this.$nextTick(() => {
      //   loadingInstance.close();
      // });
      if (res.code !== 200)
        return this.$message.error("详情获取失败，请稍后重试");
      this.quoteInfoList = res.data.list;
      this.countTotal = res.data.count;
      this.companyName = this.quoteInfoList[0].supplier.company_name;
      this.contactPhone = this.quoteInfoList[0].supplier.contact_phone;

      console.log(res);
    },
    // 分页
    handleSizeChange(val) {
      this.query.size = val;
      this.getQuoteInfoList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getQuoteInfoList();
    },
  },
};
</script>
<style scoped>
.quote_supplier {
  font-size: 18px;
  color: #20ab3a;
  margin-bottom: 10px;
}
.quote_contact {
  font-size: 16px;
  margin-bottom: 10px;
}
.pagination {
  /* width: 1200px; */
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
</style>