<template>
  <div>
    <div class="detailed_title">
      <div class="info_title">创建采购明细</div>
      <div style="display: flex">
        <el-button @click="handleDownFile" style="margin-right: 10px"
          >下载模板</el-button
        >
        <el-upload
          :action="baseUrl + 'purchase/upload/template'"
          :headers="SetHeaders"
          :data="uploadData"
          class="avatar-uploader"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
          :disabled="uploadDisableds"
        >
          <el-button @click="verification">点击上传</el-button>
        </el-upload>
      </div>
    </div>
    <div class="detailed_list">
      <el-form ref="detailedListRef" :model="detailedList">
        <el-table :data="detailedList.params" style="width: 100%" border>
          <el-table-column
            label="#"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column label="*名称">
            <template slot-scope="scope">
              <el-form-item
                :prop="'params.' + scope.$index + '.name'"
                :rules="[
                  { required: true, message: '请输入名称', trigger: 'blur' },
                ]"
              >
                <el-input type="text" v-model="scope.row.name"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="*单位">
            <template slot-scope="scope">
              <el-form-item
                :prop="'params.' + scope.$index + '.unit'"
                :rules="[
                  { required: true, message: '请输入单位', trigger: 'blur' },
                ]"
              >
                <el-input type="text" v-model="scope.row.unit"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="*数量">
            <template slot-scope="scope">
              <el-form-item
                :prop="'params.' + scope.$index + '.amount'"
                :rules="[
                  { required: true, message: '请输入数量', trigger: 'blur' },
                ]"
              >
                <el-input type="text" v-model="scope.row.amount"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="规格信息">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  type="text"
                  v-model="scope.row.spec_remark"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="其他说明">
            <template slot-scope="scope">
              <el-form-item>
                <el-input type="text" v-model="scope.row.other"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="货品图片">
            <template slot-scope="scope">
              <DetailedFormImg :data="scope.row"></DetailedFormImg>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="deleteRow(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <div style="display: flex; margin-top: 20px">
      <el-button @click="addDetailedList" class="add_detailed"
        >添加清单</el-button
      >
      <div class="save_btn" @click="save('detailedListRef')">提交清单</div>
    </div>
  </div>
</template>
<script>
import DialogImg from "../components/DialogImg";
import DetailedFormImg from "../components/DetailedFormImg";

export default {
  components: {
    DialogImg,
    DetailedFormImg,
  },
  props: {
    successSave: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      showDialog: false,
      detailedList: {
        params: [{}],
      },
      index: null,
      clear: false,
      uploadDisableds: true,
      SetHeaders: { token: "" },
      uploadData: {},
      fileList: [],
      baseUrl: "",
    };
  },
  created() {
    this.baseUrl = process.env.VUE_APP_API_URL;
  },
  methods: {
    // 打开上传图片
    openDialogImg(index) {
      this.showDialog = true;
    },
    addDetailedList() {
      this.detailedList.params.push({});
    },
    deleteRow(row) {
      var index = this.detailedList.params.indexOf(row);
      if (index !== -1) {
        this.detailedList.params.splice(index, 1);
      }
    },
    // async getOtherList() {
    //   let { data: res } = await this.$http.get("purchase/other/list?");
    // },
    // 上传前先验证是否有采购单
    verification() {
      if (!this.successSave) {
        return this.$message.error("暂未创建采购单，请创建采购单后再添加清单");
      }
      this.uploadDisableds = false;
    },
    // 下载文件
    async handleDownFile() {
      if (!this.successSave) {
        return this.$message.error("暂未创建采购单，请创建采购单后再添加清单");
      }
      let { data: res } = await this.$http.get(
        `purchase/download/template?purchase_id=${this.id}`
      );
      if (res.code !== 200) return this.$message.error("一键报价获取失败");
      // console.log(res.data.url);
      let url = res.data.url;
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      // link.setAttribute("download", "excel.xls");
      document.body.appendChild(link);
      link.click();
    },

    save(formName) {
      if (!this.successSave) {
        return this.$message.error("暂未创建采购单，请创建采购单后再添加清单");
      }
      this.$refs[formName].validate(async (valid) => {
        if (!valid) return;
        this.detailedList.params.forEach((item) => {
          item["purchase_id"] = this.id;
        });
        let { data: res } = await this.$http.post(
          "purchase/other/batch/create",
          // JSON.stringify(this.detailedList.params)
          {
            list: JSON.stringify(this.detailedList.params),
          }
        );
        if (res.code !== 200)
          return this.$message.error("提交失败，请稍后再试");
        this.$message.success("提交成功");
      });
    },

    // 上传文件时的钩子函数
    beforeAvatarUpload(file) {
      let token = localStorage.getItem("TOKEN");
      this.SetHeaders.token = JSON.parse(token);
      this.uploadData.purchase_id = this.id;
      this.uploadData.fileName = file.name;
      return this.setFileValidation(file);
    },
    // 文件验证
    setFileValidation(file) {
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      let isJPG = type == "xlsx" && true;
      !isJPG &&
        this.$message.error("上传的文件不符合规范! 请上传格式为xlsx文件");
      return isJPG;
    },

    handleAvatarSuccess(res, file) {
      if (res.code !== 200) return this.$message.error("上传失败，请稍后再试");

      if (
        this.detailedList.params[0].name ||
        this.detailedList.params[0].unit ||
        this.detailedList.params[0].amount
      ) {
        this.detailedList.params.push(...res.data);
      } else {
        this.detailedList.params = res.data;
      }
    },
  },
};
</script>
<style scoped>
.detailed_title {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info_title {
  color: #333;
  font-size: 22px;
}
.detailed_list {
  background: #f5f5f5;
  margin-top: 10px;
}
.el-form-item {
  margin-bottom: 20px;
}

.detailed_form {
  margin-left: 15px;
  margin-top: 20px;
}

.detailed_form_label {
  /* background: #e5e5e5; */
  color: #666666;
  padding: 12px 20px;
  font-size: 13px;
  list-style: none;
  display: flex;
}
.save_btn {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 100px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 4px;
  font-size: 15px;
}
.add_detailed {
  margin-right: 20px;
}
</style>