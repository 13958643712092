var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('UserHead'),_vm._m(0),_c('div',{staticClass:"settle_box"},[_c('div',{staticClass:"detailed_box_info"},[_c('p',{staticClass:"detailed_box_info_title"},[_vm._v("采购单信息")]),_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/purchase' }}},[_vm._v("采购清单 ")]),_c('el-breadcrumb-item',[_vm._v("采购单信息")])],1)],1),_c('div',{staticClass:"supply_box"},[_c('div',[_c('p',{staticClass:"info_title"},[_vm._v("基本信息")]),_c('div',{staticClass:"supply_table"},[_c('el-form',{ref:"purchaseFormRef",staticClass:"supply_table",attrs:{"model":_vm.purchaseForm,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"采购类型","prop":"purchase_category_id","rules":[
                { required: true, message: '请选择类型', trigger: 'blur' } ]}},[_c('el-cascader',{attrs:{"placeholder":"请选择类型","options":_vm.suppleOptions,"props":_vm.suppleProps,"filterable":"","clearable":""},model:{value:(_vm.purchaseForm.purchase_category_id),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "purchase_category_id", $$v)},expression:"purchaseForm.purchase_category_id"}})],1),_c('el-form-item',{attrs:{"label":"采购单名称","prop":"purchase_name","rules":[
                {
                  required: true,
                  message: '请输入采购单名称',
                  trigger: 'blur',
                } ]}},[_c('el-input',{model:{value:(_vm.purchaseForm.purchase_name),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "purchase_name", $$v)},expression:"purchaseForm.purchase_name"}})],1),_c('el-form-item',{attrs:{"label":"截止时间","prop":"deadline","rules":[
                {
                  required: true,
                  message: '请输入截止时间',
                  trigger: 'blur',
                } ]}},[_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd","type":"date","placeholder":"选择日期时间"},model:{value:(_vm.purchaseForm.deadline),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "deadline", $$v)},expression:"purchaseForm.deadline"}})],1),_c('el-form-item',{attrs:{"label":"发布单位","prop":"company_name","rules":[
                {
                  required: true,
                  message: '请输入发布单位',
                  trigger: 'blur',
                } ]}},[_c('el-input',{on:{"input":_vm.updateView},model:{value:(_vm.purchaseForm.company_name),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "company_name", $$v)},expression:"purchaseForm.company_name"}})],1),_c('el-form-item',{attrs:{"label":"联系电话","prop":"contact_phone","rules":[
                {
                  required: true,
                  message: '请输入联系电话',
                  trigger: 'blur',
                } ]}},[_c('el-input',{on:{"input":_vm.updateView},model:{value:(_vm.purchaseForm.contact_phone),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "contact_phone", $$v)},expression:"purchaseForm.contact_phone"}})],1),_c('el-form-item',{attrs:{"label":"联系人","prop":"contact_name","rules":[
                {
                  required: true,
                  message: '请输入联系人',
                  trigger: 'blur',
                } ]}},[_c('el-input',{on:{"input":_vm.updateView},model:{value:(_vm.purchaseForm.contact_name),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "contact_name", $$v)},expression:"purchaseForm.contact_name"}})],1),_c('el-form-item',{attrs:{"label":"进场车限制","prop":"car_limit","rules":[
                {
                  required: true,
                  message: '请输入进场车限制',
                  trigger: 'blur',
                } ]}},[_c('el-input',{model:{value:(_vm.purchaseForm.car_limit),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "car_limit", $$v)},expression:"purchaseForm.car_limit"}})],1),_c('el-form-item',{attrs:{"label":"进场时间","prop":"enter_limit","rules":[
                {
                  required: true,
                  message: '请输入进场时间',
                  trigger: 'blur',
                } ]}},[_c('el-input',{model:{value:(_vm.purchaseForm.enter_limit),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "enter_limit", $$v)},expression:"purchaseForm.enter_limit"}})],1),_c('el-form-item',{attrs:{"label":"报价要求","prop":"quote_type","rules":[
                {
                  required: true,
                  message: '请选择报价要求',
                  trigger: 'blur',
                } ]}},[_c('el-radio',{attrs:{"label":1},model:{value:(_vm.purchaseForm.quote_type),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "quote_type", $$v)},expression:"purchaseForm.quote_type"}},[_vm._v("到货价")]),_c('el-radio',{attrs:{"label":2},model:{value:(_vm.purchaseForm.quote_type),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "quote_type", $$v)},expression:"purchaseForm.quote_type"}},[_vm._v("上车价")])],1),_c('el-form-item',{attrs:{"label":"发票要求","prop":"invoice_type_id","rules":[
                {
                  required: true,
                  message: '请选择发票要求',
                  trigger: 'blur',
                } ]}},[_c('el-radio',{attrs:{"label":1},model:{value:(_vm.purchaseForm.invoice_type_id),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "invoice_type_id", $$v)},expression:"purchaseForm.invoice_type_id"}},[_vm._v("增值税专用发票")]),_c('el-radio',{attrs:{"label":2},model:{value:(_vm.purchaseForm.invoice_type_id),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "invoice_type_id", $$v)},expression:"purchaseForm.invoice_type_id"}},[_vm._v("增值税普通发票")]),_c('el-radio',{attrs:{"label":3},model:{value:(_vm.purchaseForm.invoice_type_id),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "invoice_type_id", $$v)},expression:"purchaseForm.invoice_type_id"}},[_vm._v("普通发票")]),_c('el-radio',{attrs:{"label":4},model:{value:(_vm.purchaseForm.invoice_type_id),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "invoice_type_id", $$v)},expression:"purchaseForm.invoice_type_id"}},[_vm._v("不需要发票")])],1),_c('el-form-item',{attrs:{"label":"含税要求","prop":"purchase_tax_id","rules":[
                {
                  required: true,
                  message: '请选择含税要求',
                  trigger: 'blur',
                } ]}},[_c('el-radio',{attrs:{"label":1},model:{value:(_vm.purchaseForm.purchase_tax_id),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "purchase_tax_id", $$v)},expression:"purchaseForm.purchase_tax_id"}},[_vm._v("不含税")]),_c('el-radio',{attrs:{"label":2},model:{value:(_vm.purchaseForm.purchase_tax_id),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "purchase_tax_id", $$v)},expression:"purchaseForm.purchase_tax_id"}},[_vm._v("含税")])],1),_c('el-form-item',{attrs:{"label":"税点"}},[_c('el-input',{model:{value:(_vm.purchaseForm.tax_point),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "tax_point", $$v)},expression:"purchaseForm.tax_point"}})],1),_c('el-form-item',{attrs:{"label":"采购地址","prop":"address","rules":[
                {
                  required: true,
                  message: '请输入采购地址',
                  trigger: 'blur',
                } ]}},[_c('el-input',{model:{value:(_vm.purchaseForm.address),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "address", $$v)},expression:"purchaseForm.address"}})],1),_c('el-form-item',{attrs:{"label":"备注信息"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.purchaseForm.other),callback:function ($$v) {_vm.$set(_vm.purchaseForm, "other", $$v)},expression:"purchaseForm.other"}})],1),_c('el-form-item',{staticClass:"itemImg",attrs:{"label":"相关附件"}},[_c('UploadData',{attrs:{"echoData":_vm.echoData,"purchaseForm":_vm.purchaseForm},on:{"images_path":_vm.imagesPath}})],1),_c('el-form-item',[_c('div',{staticClass:"save_btn",on:{"click":_vm.save}},[_vm._v("提交基本信息")])])],1)],1)]),(!_vm.successSave)?_c('div',[_c('DetailedLists',{attrs:{"successSave":_vm.successSave,"id":_vm.id}})],1):_vm._e()])]),_c('FooterInfo')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',[_vm._v("banner部分")])])}]

export { render, staticRenderFns }