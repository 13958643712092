import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import db from './utils/localstorage'
import service from './utils/request'
import './plugins/element.js'

import component from './components'

// 导入全局样式表
import './assets/css/global.css'
import './assets/font/iconfont.css'

Vue.config.productionTip = false


Vue.use({
  install(Vue) {
    Vue.prototype.$db = db
  }
});

Vue.use(component)

Vue.prototype.$http = service;

Vue.prototype.$post = service.post;
Vue.prototype.$get = service.get;
Vue.prototype.$put = service.put;
Vue.prototype.$delete = service.delete;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
