<template>
  <div>
    <!-- 头部状态条 -->
    <HeaderInfo></HeaderInfo>
    <!-- 信息部分 -->
    <div>
      <div class="supplier_box">
        <div class="purchase_detail">
          <div class="purchase_detail_banner" v-if="showImg">
            <!-- <img src="" alt="" /> -->
            <el-carousel :interval="3000" :arrow="arrow">
              <el-carousel-item
                v-for="(img, index) in item.images"
                :key="index"
              >
                <img v-bind:src="img" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="purchase_info_detail">
            <div class="purchase_title">
              <p class="purchase_name">{{ item.company_name }}</p>
              <p class="purchase_count" v-if="item.provider_number > 0">
                {{ item.provider_number }}条供应
              </p>
              <p class="purchase_count" v-else>暂无供应</p>
            </div>
            <p class="purchase_demand">供应商信息</p>
            <div class="purchase_demand_info">
              <p>
                <span>
                  联系方式：{{ item.contact_name }}
                  {{ item.contact_phone }}
                </span>
                <span>公司地址：{{ item.address }}</span>
              </p>
              <p>
                <span>主营内容：{{ supply_type }}</span>
                <span v-if="address">所在城市：{{ address }}</span>
              </p>

              <p>
                其他说明：{{ item.business_info ? item.business_info : "无" }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 供应列表 -->
      <div class="detailed_box" v-if="supplierList.length > 0">
        <div class="detailed_box_info">
          <p class="detailed_box_info_title">采购清单</p>
        </div>
      </div>
      <div class="detailed_list_box">
        <div
          class="detailed_list"
          v-for="(item, index) in supplierList"
          :key="index"
        >
          <div class="detailed_number">{{ item.id }}</div>
          <div>
            <div class="detailed_right">
              <p class="detailed_name">{{ item.name }}</p>
              <p>类型：{{ item.category.purchase_name }}</p>
              <p>备注：{{ item.remark ? item.remark : "无" }}</p>
              <div v-for="(imgList, index) in item.images_path" :key="index">
                <img :src="imgList.url" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="query.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="detailedListTotal"
            :hide-on-single-page="hideSinglePage"
          >
          </el-pagination>
        </div>
    </div>
    <!-- 页脚部分 -->
    <FooterInfo></FooterInfo>
  </div>
</template>
<script>
export default {
  components: {
    
  },
  data() {
    return {
      id: "",
      item: {},
      arrow: "never",
      showImg: false,
      address: "",
      supply_type: "",
      supplierList: [],
      query: {
        page: 1,
        size: 10,
      },
      detailedListTotal: 0,
      hideSinglePage: true,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getSupplierDetail();
    this.getSupplierList();
  },
  methods: {
    async getSupplierDetail() {
      let { data: res } = await this.$http.get(
        `v1/supplier/find?id=${this.id}`
      );
      if (res.code !== 200) return this.$message.error("获取失败");
      this.item = res.data;
      this.address =
        this.item.province +
        (this.item.city == this.item.province ? "" : this.item.city) +
        this.item.district;
      if (this.item.images.length > 0) {
        this.showImg = true;
      }
      this.getType();
    },
    // 主营信息处理
    getType() {
      var that = this;
      let type = [];
      that.item.detail_type.forEach((item1, index1) => {
        item1.children.forEach((item2, index2) => {
          if (item2.checked) {
            if (item2.purchase_name === "全部") {
              type.push(item1.purchase_name);
            } else {
              type.push(item2.purchase_name);
            }
          }
        });
      });
      if (type.length > 0) {
        that.supply_type = type.join(",");
      } else {
        that.supply_type = "";
      }
    },
    // 获取供应信息的列表
    async getSupplierList() {
      let { data: res } = await this.$http.get("v1/supplier/business/list", {
        params: { supplier_id: this.id, ...this.query },
      });
      if (res.code !== 200)
        return this.$message.error("供应信息获取失败，请稍后再试");
      this.supplierList = res.data.list;
      this.detailedListTotal = res.data.count;
    },
    // 分页
    handleSizeChange(val) {
      this.query.size = val;
      this.getSupplierList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getSupplierList();
    },
  },
};
</script>
<style scoped>
.supplier_box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 20px;
}

.purchase_detail {
  display: flex;
  padding-bottom: 20px;
}
.purchase_detail_banner {
  width: 280px;
  height: 280px;
}
.purchase_info_detail {
  margin-left: 20px;
  width: 100%;
}
.purchase_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.purchase_name {
  font-size: 26px;
  color: #333;
}
.purchase_label {
  font-size: 17px;
  background: #20ab3a;
  color: #fff;
  padding: 2px 5px;
  margin-left: 10px;
}
.purchase_count {
  font-size: 22px;
  color: #dc5b56;
}
.deadline {
  color: #333;
  font-size: 16px;
  margin-top: 10px;
}
.purchase_financing {
  color: #20ab3a;
  cursor: pointer;
}
.financing_btn {
  color: #f97d03;
  cursor: pointer;
  margin-left: 10px;
}
.purchase_demand {
  margin-top: 30px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.purchase_demand_info {
  margin-top: 15px;
}
.purchase_demand_info > p {
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}
.purchase_demand_info > p > span {
  margin-right: 20px;
}

/* 清单列表 */
.detailed_box {
  width: 100%;
  background: #fff;
}
.detailed_box_title {
  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
}
.detailed_list_box {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}
.detailed_list {
  width: 100%;
  background: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
}
.detailed_number {
  background: #20ab3a;
  color: #fff;
  /* padding: 5px; */
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.detailed_right {
  margin-left: 15px;
}
.detailed_right > p {
  margin-bottom: 6px;
  color: #666666;
  font-size: 14px;
}
.detailed_name {
  color: #333 !important;
  font-size: 20px !important;
}
.unit {
  margin-right: 20px;
}

.pagination {
  width: 1200px;
  margin: 0 auto;
  /* margin-top: 20px; */
  padding-left: 20px;
  padding-bottom: 20px;
}
</style>>
