import db from '../../utils/localstorage'
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    loginToken: db.get('TOKEN'),
    expireTime: db.get('EXPIRE_TIME'),
    user: db.get('USER'),
    permissions: db.get('PERMISSIONS'),
    roles: db.get('ROLES')
  },
  mutations: {
    setToken (state, val) {
      db.save('TOKEN', val)
      state.loginToken = val
    },
    setFinancePrivilege(stats,val){
      db.save('FINANCE_PRIVILEGE', val)
    },
    setExpireTime (state, val) {
      db.save('EXPIRE_TIME', val)
      state.expireTime = val
    },
    setUser (state, val) {
      db.save('USER', val)
      state.user = val
    },
    setPermissions (state, val) {
      db.save('PERMISSIONS', val)
      state.permissions = val
    },
    setRoles (state, val) {
      db.save('ROLES', val)
      state.roles = val
    }
  }
}
