import Vue from 'vue'
import { Button,Form,FormItem,Input,Carousel,CarouselItem,Pagination,Dialog,Upload,Cascader,Col,
  Row, Table,TableColumn,Switch,Radio,Breadcrumb,BreadcrumbItem,Image,DatePicker,Select,Option,Tabs,TabPane} from 'element-ui'
import { Message,MessageBox } from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(Cascader)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Image)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tabs)
Vue.use(TabPane)













// 弹窗组件挂载到原型上
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm