<template>
  <div>
    <UserHead></UserHead>
    <!-- banner -->
    <div class="banner">
      <div>banner部分</div>
    </div>
    <!-- 内容部分 -->
    <div class="settle_box">
      <div class="detailed_box_info">
        <p class="detailed_box_info_title">入驻申请</p>
      </div>
      <div class="settle_form">
        <el-form ref="settleFormRef" :model="settleForm" label-width="100px">
          <el-form-item
            label="公司名称"
            prop="company_name"
            :rules="[
              { required: true, message: '请输入公司名称', trigger: 'blur' },
            ]"
          >
            <el-input v-model="settleForm.company_name"></el-input>
          </el-form-item>
          <el-form-item
            label="联系人"
            prop="contact_name"
            :rules="[
              { required: true, message: '请输入联系人', trigger: 'blur' },
            ]"
          >
            <el-input v-model="settleForm.contact_name"></el-input>
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="contact_phone"
            :rules="[
              { required: true, message: '请输入联系电话', trigger: 'blur' },
            ]"
          >
            <el-input v-model="settleForm.contact_phone"></el-input>
          </el-form-item>
          <el-form-item label="主营内容">
            <el-cascader
              placeholder="请选择主营内容"
              v-model="SelectdeptId"
              :options="cascaderOptions"
              :props="cascaderPopup"
              @change="changeCascader"
              filterable
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="所在城市">
            <!-- <el-input
              v-model="settleForm.district"
              placeholder="请输入所在城市"
            ></el-input> -->
            <el-cascader
              placeholder="请选择所在城市"
              v-model="addressSelect"
              :options="addressOptions"
              :props="addressPopup"
              @change="changeAddress"
              size="small"
              filterable
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="公司地址">
            <el-input
              v-model="settleForm.address"
              placeholder="请输入公司地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="其他说明">
            <el-input
              type="textarea"
              v-model="settleForm.business_info"
              placeholder="描述更多信息,有助于更好的展示公司信息"
            ></el-input>
          </el-form-item>
          <el-form-item label="相关附件">
            <el-upload
              :action="domain"
              :data="qiNiuData"
              class="upload-idCard"
              list-type="picture-card"
              :file-list="defaultImg"
              ref="imgRef"
              :on-preview="handleShow"
              :on-error="handleError"
              :on-success="handleAvatarSuccess"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :before-upload="beforeAvatarUpload"
              :limit="6"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="isShowImg" width="50%" size="tiny">
              <img width="100%" :src="myFileUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save">立即提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <FooterInfo></FooterInfo>
  </div>
</template>
<script>
import UserHead from "../components/UserHeader.vue";
import FooterInfo from "../components/Footer";
import { Loading } from "element-ui";
export default {
  components: {
    UserHead,
    FooterInfo,
  },
  data() {
    return {
      settleForm: {},
      domain: "https://upload-z2.qiniup.com",
      BASE_URL: "https://gylian.yiputouzi.com/",
      qiNiuData: {},
      defaultImg: [],
      myFileUrl: "",
      images_path: [],
      isShowImg: false,
      cascaderPopup: {
        multiple: true,
        expandTrigger: "hover",
        value: "purchase_id",
        label: "purchase_name",
        children: "children",
      },
      cascaderOptions: [],
      SelectdeptId: [],
      Selectdept: [],
      addressSelect: [],
      addressOptions: [],
      addressPopup: {
        expandTrigger: "hover",
        value: "name",
        label: "name",
        children: "children",
      },
      supply_id: [],
    };
  },
  created() {
    this.getType();
    this.getAddress();
    this.getFindInfo();
    this.getQiNiuToken();
  },
  methods: {
    // 首次进入获取数据
    async getFindInfo() {
      // let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("v1/user/ident/find");
      // this.$nextTick(() => {
      //   // 以服务的方式调用的 Loading 需要异步关闭
      //   loadingInstance.close();
      // });
      if (res.code !== 200)
        return this.$message.error("获取信息失败，请稍后再试");
      this.settleForm = res.data;
      // 地址回显
      this.addressSelect = [
        this.settleForm.province,
        this.settleForm.city ? this.settleForm.city : this.settleForm.province,
        this.settleForm.district,
      ];
      // 图片回显

      this.settleForm.images.forEach((item) => {
        let obj = new Object();
        let img_path = {};
        img_path.hash = item.images_path;
        img_path.url = item.banner;
        this.images_path.push(img_path);
        obj.response = {};
        obj.response.hash = item.images_path;
        obj.url = item.banner;
        this.defaultImg.push(obj);
      });
      console.log(this.defaultImg);
    },

    // 选择主营项目
    changeCascader(data) {
      let parent_id = [];
      let type = [];
      data.forEach((item) => {
        this.Selectdept = this.SelectdeptId;
        this.countTypeId(item);
      });
      // if (this.SelectdeptId.length > 0) {
      //   this.SelectdeptId.forEach((item, index) => {
      //     this.countTypeId(item);

      //     if (item[0] === item[1]) {
      //       parent_id.push(item[0]);
      //       // this.supply_id[item[0] - 1] = item[0];
      //     } else {
      //       type.push(item[1]);
      //     }
      //   });
      //   this.settleForm.parent_id = parent_id.join(",");
      //   this.settleForm.type = type.join(",");
      // } else {
      //   this.settleForm.parent_id = "";
      //   this.settleForm.type = "";
      // }
      // let typeId = this.settleForm.supply_type_id.split(",");
      // console.log(typeId, this.supply_id);
      // console.log(this.supply_id);
    },

    countTypeId(item) {
      // let arr =[[1,1],[1,2],[1,3],[2,2],[3,3],[3,4],[3,6]]
      //每次点击时向数组中添加数组，当要添加的数组中的值都不一样（例如[1,2]），判断arr中是否包含[1,1]的数组，如果有则删除[1,1]
      //添加类似[1,1]相同值的数组时，判断arr中是否包含第一位是1的数组，如果有就删除所有第一位为1的数组
      // console.log(item[0], item[1]);
      // 当数组中两个值相同，选中是全部，则删除所有第一个值为item[0]的数组
      // if (item[0] === item[1]) {
      //   this.SelectdeptId.forEach((val, index) => {
      //     if (val[0] === item[0] && val[0] !== val[1]) {
      //       this.Selectdept.splice(index, 1);
      //       console.log(123);
      //     }
      //   });
      // }
      // console.log(this.SelectdeptId);
      // this.cascaderOptions.forEach((val, index) => {
      //   if (val.purchase_id === item[1]) {
      //     this.supply_id[index] = item[0];
      //   }
      // });
    },

    async getQiNiuToken() {
      let { data: res } = await this.$http.get("v1/common/qiniu/token");
      this.qiNiuData.token = res.data.token;
    },
    async getType() {
      let { data: res } = await this.$http.post("v1/user/ident/type");
      this.cascaderOptions = res;
      this.cascaderOptions.forEach(() => {
        this.supply_id.push(-1);
      });
    },
    // 获取省市区数据
    async getAddress() {
      let { data: res } = await this.$http.get("config/address");
      this.addressOptions = res.data;
    },
    changeAddress(data) {
      this.settleForm.province = data[0];
      this.settleForm.city = data[1];
      this.settleForm.district = data[2];
      console.log(this.settleForm);
    },

    // 上传文件时的钩子函数
    beforeAvatarUpload(file) {
      // console.log("上传文件时的钩子函数");
      return this.setFileValidation(file);
    },
    // 文件验证
    setFileValidation(file) {
      this.qiNiuData.key = file.name;
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      let isJPG = (type == "jpg" || type == "png" || type == "gif") && true;
      !isJPG &&
        this.$message.error("上传的文件不符合规范!,支持格式:png/jpg/gif");
      return isJPG;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多只能上传6张图片，请删除后再添加`);
    },
    // 选择时触发
    handleChange(file) {
      // console.log("选择时触发", file);
      // this.hideUpload = this.defaultImg.length >= this.limitCount;
    },
    // 成功之后判断类型
    isSuccessFileType(key) {
      // console.log("成功之后判断类型");
      // let arr = key.split(".");
      // return arr[arr.length - 1];
    },
    // 返回错误
    handleError() {
      Message({
        message: "文件上传失败",
        type: "error",
        duration: 5 * 1000,
      });
    },

    // 成功返回
    handleAvatarSuccess(res, file) {
      // console.log("成功返回", res);
      this.myFileUrl = this.BASE_URL + res.hash;
      let img = { hash: res.hash, url: this.myFileUrl };
      this.images_path.push(img);
      console.log(this.images_path);
    },

    handleRemove(file, fileList) {
      // console.log("删除", file, fileList);
      if (fileList.length > 0) {
        let imgUrl = {};
        this.images_path = [];
        fileList.forEach((item) => {
          imgUrl = {
            hash: item.response.hash,
            url: this.BASE_URL + item.response.hash,
          };
          this.images_path.push(imgUrl);
        });
      } else {
        this.images_path = [];
      }
      // console.log(this.images_path);
    },
    // 点击文件列表中已上传的文件时的钩子
    async handleShow(file) {
      this.myFileUrl = file.url;
      this.isShowImg = true;
      // this.myFileUrl = this.BASE_URL + this.myHashUrl;
      // this.isType && (await this.openFile());
    },

    // 提交表单
    save() {
      this.$refs.settleFormRef.validate(async (valid) => {
        if (!valid) return;
        console.log(this.settleForm, this.images_path);

        let data = {
          company_name: this.settleForm.company_name,
          contact_name: this.settleForm.contact_name,
          contact_phone: this.settleForm.contact_phone,
          province: this.settleForm.province || "",
          city: this.settleForm.city || "",
          district: this.settleForm.district || "",
          address: this.settleForm.address || "",
          latitude: this.settleForm.latitude,
          longitude: this.settleForm.longitude,
          business_info: this.settleForm.business_info || "",
          // type: this.settleForm.type || "",
          parent_id: this.settleForm.parent_id || "",
          images_path: JSON.stringify(this.images_path),
        };
        let { data: res } = await this.$http.post("v1/user/ident/create", {
          ...data,
        });
        if (res.code !== 200) return this.$message.error("提交入驻申请失败");
        this.$message.success("提交成功");
        this.defaultImg = [];
        this.images_path = [];
        this.getFindInfo();
        // console.log(this.settleForm, this.images_path);
      });
    },
  },
};
</script>
<style scoped>
.banner {
  width: 1200px;
  height: 240px;
  line-height: 240px;
  text-align: center;
  margin: 20px auto;
  background: #d8d8d8;
}

.settle_box {
  width: 100%;
  background: #fff;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
}
.settle_form {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0px 30px 60px;
}
.el-form {
  width: 580px;
}
.el-cascader {
  width: 100%;
}
</style>