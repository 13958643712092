<template>
  <div>
    <UserHead></UserHead>
    <!-- banner -->
    <div class="banner">
      <div>banner部分</div>
    </div>
    <!-- 内容部分 -->
    <div class="settle_box">
      <div class="detailed_box_info">
        <p class="detailed_box_info_title">融资申请</p>
      </div>
      <div class="supply_box">
        <p class="title m_b">需要达到以下条件才能申请：</p>
        <p class="m_b">1.园林惠供应链管理平台认可的供应商</p>
        <p class="m_b">2.公司营业执照满一年</p>
        <p>3.主借人征信无不良记录</p>
      </div>
      <div class="supply_box">
        <p class="title m_b">上传附件</p>
        <div class="finance_list">
          <div>
            <p>营业执照</p>
            <UploadFinance
              @images_path="businessLicense"
              :imagePath="financing.business_license"
            ></UploadFinance>
          </div>
          <div>
            <p>法人或股东身份证正面</p>
            <UploadFinance
              @images_path="cardPositive"
              :imagePath="financing.corporate_identity_card_positive"
            ></UploadFinance>
          </div>
          <div>
            <p>法人或股东身份证反面</p>
            <UploadFinance
              @images_path="cardBack"
              :imagePath="financing.corporate_identity_card_back"
            ></UploadFinance>
          </div>
          <div>
            <p>供应合同</p>
            <UploadFinance
              @images_path="contract"
              :uploadType="uploadType"
              :imagePath="financing.contract"
            ></UploadFinance>
          </div>
          <div>
            <p>结算确认单</p>
            <UploadFinance
              @images_path="settlementConfirmation"
              :uploadType="uploadType"
              :imagePath="financing.settlement_confirmation"
            ></UploadFinance>
          </div>
          <div>
            <p>发票</p>
            <UploadFinance
              @images_path="invoice"
              :uploadType="uploadType"
              :imagePath="financing.invoice"
            ></UploadFinance>
          </div>
        </div>
        <el-form ref="financeFormRef" :model="financing" label-width="80px">
          <el-form-item
            label="联系电话"
            prop="mobile"
            :rules="[
              { required: true, message: '请输入联系电话', trigger: 'blur' },
            ]"
          >
            <el-input v-model="financing.mobile"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </div>
    <FooterInfo></FooterInfo>
  </div>
</template>
<script>
import UserHead from "../components/UserHeader.vue";
import FooterInfo from "../components/Footer";
import UploadFinance from "../components/UploadFinance";

import { Loading } from "element-ui";
export default {
  components: {
    UserHead,
    FooterInfo,
    UploadFinance,
  },
  data() {
    return {
      showDialog: false,
      uploadType: true,
      financing: {},
    };
  },
  created() {
    this.getFinanceDetail();
  },
  methods: {
    // 营业执照
    businessLicense(e) {
      this.financing.business_license = JSON.stringify(e);
    },
    //法人或股东身份证正面
    cardPositive(e) {
      this.financing.corporate_identity_card_positive = JSON.stringify(e);
    },
    //法人或股东身份证反面
    cardBack(e) {
      this.financing.corporate_identity_card_back = JSON.stringify(e);
    },
    //供应合同
    contract(e) {
      this.financing.contract = JSON.stringify(e);
    },
    //结算确认单
    settlementConfirmation(e) {
      this.financing.settlement_confirmation = JSON.stringify(e);
    },
    //发票
    invoice(e) {
      this.financing.invoice = JSON.stringify(e);
    },

    // 加载详情
    async getFinanceDetail() {
      let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("v1/financing/detail");
      this.$nextTick(() => {
        loadingInstance.close();
      });
      if (res.code !== 200) return this.$message.error("加载失败，请稍后重试");
      this.financing = res.data;
    },

    // 提交信息
    save() {
      if (!this.financing.business_license) {
        this.$message.error("请上传营业执照");
        return;
      }

      if (!this.financing.corporate_identity_card_positive) {
        this.$message.error("请上传法人或股东身份证正面");
        return;
      }

      if (!this.financing.corporate_identity_card_back) {
        this.$message.error("请上传法人或股东身份证反面");
        return;
      }

      if (!this.financing.contract) {
        this.$message.error("请上传供应合同");
        return;
      }

      if (!this.financing.settlement_confirmation) {
        this.$message.error("结算确认单");
        return;
      }

      if (!this.financing.invoice) {
        this.$message.error("请上传发票");
        return;
      }

      this.$refs.financeFormRef.validate(async (valid) => {
        if (!valid) return this.$message.error("请输入联系电话");
        let loadingInstance = Loading.service();
        setTimeout(() => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
        }, 300);
        let { data: res } = await this.$http.post("v1/financing/create", {
          ...this.financing,
        });

        if (res.code !== 200)
          return this.$message.error("提交失败，请稍后再试");
        this.$message.success("提交成功");
        this.getFinanceDetail();
      });
    },
  },
};
</script>
<style scoped>
.banner {
  width: 1200px;
  height: 240px;
  line-height: 240px;
  text-align: center;
  margin: 20px auto;
  background: #d8d8d8;
}

.settle_box {
  width: 100%;
  background: #fff;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
}

.supply_box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 10px;
}
.supply_table {
  margin-top: 30px;
}

.pagination {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.m_b {
  margin-bottom: 10px;
}
.finance_list {
  display: flex;
  flex-wrap: wrap;
}
.finance_list > div {
  width: 33%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.finance_list > div > p {
  margin-bottom: 10px;
}
.el-form-item {
  width: 400px;
}
</style>