import HeaderInfo from "./Header.vue";
import FooterInfo from "./Footer.vue";


const install = vue => {
  vue.component(HeaderInfo.name, HeaderInfo);
  vue.component(FooterInfo.name, FooterInfo);
};

export default install;
