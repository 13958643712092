<template>
  <div>
    <span @click="openDialogImg">上传图片</span>
    <DialogImg
      :showDialog.sync="showDialog"
      :imgUrl="data.images_path"
      @images_path="images_path"
      @handleClose="handleClose"
    ></DialogImg>
  </div>
</template>
<script>
import DialogImg from "../components/DialogImg";

export default {
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    DialogImg,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    images_path(e) {
      this.data.images_path = JSON.stringify(e);
    },
    openDialogImg() {
      this.showDialog = true;
    },
    handleClose(){
      this.showDialog = false;
    }
  },
};
</script>
<style scoped>
</style>