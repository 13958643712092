<template>
  <div>
    <el-dialog
      title="绑定手机号"
      :visible.sync="bindMobileShow"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      >
      <span>您还未绑定手机号，先请绑定手机号！</span>
      <el-form
            ref="bindFormRef"
            :model="bindForm"
            label-width="0px"
            class="login_form"
          >
            <!-- 用户名 -->
            <el-form-item
              class="phone"
              prop="mobile"
              :rules="[
                { required: true, message: '请输入手机号', trigger: 'blur' },
              ]"
            >
              <el-input
                prefix-icon="el-icon-user"
                v-model="bindForm.mobile"
                placeholder="请输入手机号"
              >
              </el-input>
              <el-button
                type="success"
                class="phone_code"
                v-if="!isDisabled"
                @click="getPhoneCode"
                >{{ content }}</el-button
              >
              <el-button
                type="info"
                disabled
                class="phone_code"
                v-if="isDisabled"
                @click="getPhoneCode"
                >{{ content }}</el-button
              >
            </el-form-item>
            <el-form-item
              prop="code"
              :rules="[
                { required: true, message: '请输入验证码', trigger: 'blur' },
              ]"
            >
              <el-input
                prefix-icon="el-icon-lock"
                v-model="bindForm.code"
                placeholder="请输入验证码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div class="btns" @click="bindMobile">立即绑定</div>
            </el-form-item>
          </el-form>
      
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props:{
    bindMobileShow:{
      type:Boolean,
      default:false
    },
    bindToken:{
      type:String,
    }
  },
  data(){
    return{
      bindForm:{
        mobile:'',
        code:''
      },
      isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
      content: "获取验证码",
      timer: null,
      count: "",
    }
  },
  methods:{
    ...mapMutations({
      setToken: "account/setToken",
      setUser: "account/setUser",
    }),
    async getPhoneCode() {
      if (!this.bindForm.mobile || this.bindForm.mobile == "") {
        return this.$message.error("请输入手机号");
      }
      if (!/^1[34578]\d{9}$/.test(this.bindForm.mobile)) {
        return this.$message.error("请输入正确的手机号");
      }
      // 请求后台发送验证码至手机;
      this.getVerifyCode(this.bindForm.mobile);
      const TIME_COUNT = 60;
      this.count = TIME_COUNT;
      this.timer = window.setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          // 倒计时时不可点击
          this.isDisabled = true;
          // 计时秒数
          this.count--;
          // 更新按钮的文字内容
          this.content = this.count + "s后重新获取";
        } else {
          // 倒计时完，可点击
          this.isDisabled = false;
          // 更新按钮文字内容
          this.content = "获取短信验证码";
          // 清空定时器!!!
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },

    // 获取验证码
    async getVerifyCode(mobile) {
      let { data: res } = await this.$http.post(`v1/send/code`, {
        mobile: mobile,
        send_type: 1,
      });

      if (res.code !== 200)
        return this.$message.error("验证码发送失败，请稍后重试");
      this.$message.success("验证码发送成功");
      this.isDisabled = false;
    },

    // 绑定手机号
    bindMobile(){
      this.bindForm.bindToken = this.bindToken;
      this.$refs.bindFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post(
          "v1/user/bind/mobile",
          this.bindForm
        );
        if (res.code === 200) {
          this.saveLoginData(res.data);
        }
        this.$router.push("/");
      });
    },
    //将登录后的信息通过vuex存储到本地中
    saveLoginData(data) {
      // token
      this.setToken(data.token);
      // 用户信息
      this.setUser(data.user);
    },

  }
}
</script>

<style lang="css" scoped>
  .login_form {
  /* position: absolute;
  top: 80px; */
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
}
.phone {
  position: relative;
}
.phone_code {
  height: 100%;
  position: absolute;
  right: 0px;
}
.btns {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 100%;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 20px;
}
/* /deep/.el-button--primary {
    color: #FFF;
    background-color: #20ab3a;
    border-color: #20ab3a;
} */
/* /deep/.el-button:hover {
    color: #FFF;
    background-color: #20ab3a;
} */

/deep/.el-tabs__item.is-active {
    color: #20ab3a;
}
/deep/.el-tabs__item:hover {
    color: #20ab3a;
}
/deep/.el-tabs__active-bar{
  background-color: #20ab3a;
}
</style>