<template>
  <div>
    <!-- 头部状态条 -->
    <HeaderInfo></HeaderInfo>
    
    <div class="contain">
      <el-form
        ref="loginFormRef"
        :model="filterForm"
        size="medium"
        class="filter_form"
      >
        <el-form-item label="业务类型">
          <el-select v-model="filterForm.type" clearable placeholder="请选择">
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="团队所在地">
          <el-select
            v-model="filterForm.province_id"
            clearable
            placeholder="请选择省份"
            @change="changeProvince"
          >
            <el-option
              v-for="item in provinceOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="filterForm.city_id"
            clearable
            placeholder="请选择市区"
          >
            <el-option
              v-for="item in cityOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div style="display:flex">
            <div class="search" @click="search">查 询</div>
            <div class="btn" @click="reset">重置</div>
          </div>
        </el-form-item>
      </el-form>

      <div class="list">
        <div v-for="item in teamList" :key="item.id" class="item_list">
          <div class="cover">
            <img :src="item.cover" alt="">
          </div>
          <div style="max-width:760px">
            <p class="title">{{ item.title }}</p>
            <div>
              <span class="label">团队所在地：{{item.locate}}</span>
              <span class="type">业务类型：</span>
              <template v-for="type in item.type" :key="">
                <span v-if="type === 1" class="type" style="margin-right:10px">设计</span>
                <span v-if="type === 2" class="type" style="margin-right:10px">施工</span>
                <span v-if="type === 3" class="type">养护</span>
              </template>
              <span class="label" style="margin-left:20px">团队成员：{{item.json ? item.json.member_count : 0}}人</span>
              <span class="label">服务等级：{{item.json.rating}}</span>
            </div>
            <p style="margin-top:10px;color:#666;font-size:16px">团队简介：</p>
            <p style="margin-top:10px;color:#666;font-size:16px" class="overflow2">{{item.content}}</p>
          </div>
          <div class="btn_position">
            <div class="btn" @click="changeTel(item)">联 系</div>
            <div class="btn" @click="goTeamDetail(item)">查看详情</div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="filterForm.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="filterForm.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="listTotal"
            :hide-on-single-page="hideSinglePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <FooterInfo/>

    <el-dialog
      title="联系方式"
      :visible.sync="showTel"
      width="20%"
      top="30vh"
      >
      <p>手机号：{{contact.tel}}</p>
      <p>联系电话：{{contact.mobile}}</p>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeTel">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import wxlogin from "vue-wxlogin";
export default {
  components: { wxlogin },
  data() {
    return {
      filterForm: {
        type: "",
        province_id: "",
        city_id: "",
        size:10,
        page:1
      },
      listTotal: 0,
      typeOptions: [],
      typeSelect:'',
      addressOptions: [],
      provinceOptions: [],
      cityOptions: [],
      teamList:[],
      showTel:false,
      contact:{
        tel:'',
        mobile:''
      },
      hideSinglePage: true,
    };
  },
  created() {
    this.getAddress();
    this.getTeamList()
  },
  methods: {
    // 获取省市区数据
    async getAddress() {
      let { data: res } = await this.$http.get("/v1/people/teams/filter");
      this.addressOptions = res.data.area;
      this.provinceOptions = this.filtersProvince(res.data.area);
      this.typeOptions = res.data.types;
    },
    filtersProvince(item) {
      return item.filter((list) => {
        return list.parent_id == 1;
      });
    },
    changeProvince() {
      let provinceSelect = this.addressOptions.filter((item) => {
        return item.code === this.filterForm.province_id;
      });
      this.cityOptions = this.addressOptions.filter((item) => {
        return item.parent_id === provinceSelect[0].id;
      });
      this.filterForm.city_id = "";
    },

    // 筛选
    search(){
      this.getTeamList()
    },
    reset(){
      this.filterForm.province_id = ""
      this.filterForm.city_id = ""
      this.filterForm.type = ""
      this.cityOptions=[]
      this.getTeamList()
    },
    // 获取列表数据
    async getTeamList() {
      let { data: res } = await this.$http.get("/v1/people/teams",{
        params:{ ...this.filterForm }
      });
      this.teamList = res.data.list
      this.listTotal = res.data.count;
    },
    changeTel(item){
      this.contact.tel = item.json.mobile
      this.contact.mobile = item.json.mobile
      this.showTel=true
    },
    closeTel(){
      this.contact={}
      this.showTel=false
    },
    goTeamDetail(item){
      this.$router.push({
        path: "/teamDetail",
        query: {
          id:item.id
          // item: JSON.stringify(item),
        },
        // query: {
        //   item: JSON.stringify(item),
        // },
      });
    },
    // 分页
    handleSizeChange(val) {
      this.filterForm.size = val;
      this.getTeamList();
    },
    handleCurrentChange(val) {
      this.filterForm.page = val;
      this.getTeamList();
    },

  },
};
</script>

<style scoped>
.contain{
  width: 1200px;
  margin: 0 auto;
}
.filter_form{
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.el-form-item{
  min-width: 25%
}
.list{
  width: 100%;
}
.item_list{
  padding:20px 0;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  width: 100%;
  position: relative;
}
.cover{
  width: 200px;
  height: 200px;
  margin-right: 20px;
}
.title{
  font-size: 24px;
  margin-bottom: 10px;
}
.label{
  color: #666;
  font-size: 16px;
  margin-right: 20px
}
.type{
  color: #666;
  font-size: 16px;
}
.search {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
}
.btn_position{
  position: absolute;
  right:0px;
  bottom: 15px;
  display: flex;
}
.btn {
  /* background: #20ab3a; */
  border:1px solid #20ab3a;
  color: #20ab3a;
  cursor: pointer;
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 20px;
}
.pagination {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}
</style>