<template>
  <div>
    <!-- 头部状态条 -->
    <HeaderInfo></HeaderInfo>

    <div class="contain">
      <div class="item_list">
        <div style="display: flex">
          <div class="cover">
            <img :src="item.cover" alt="" />
          </div>
          <div style="max-width: 760px">
            <p class="title">{{ item.title }}</p>
            <div>
              <span class="label">团队所在地：{{ item.locate }}</span>
              <span class="type">业务类型：</span>
              <template v-for="type in item.type" :key="">
                <span v-if="type === 1" class="type" style="margin-right: 10px"
                  >设计</span
                >
                <span v-if="type === 2" class="type" style="margin-right: 10px"
                  >施工</span
                >
                <span v-if="type === 3" class="type">养护</span>
              </template>
              <span class="label" style="margin-left: 20px"
                >团队成员：{{ item.json ? item.json.member_count : 0 }}人</span
              >
              <span class="label"
                >服务等级：{{ item.json ? item.json.rating : "" }}</span
              >
            </div>
            <p style="margin-top: 10px; color: #666; font-size: 16px">
              团队简介：
            </p>
            <p style="margin: 10px 0px 20px 0px; color: #666; font-size: 16px">
              {{ item.content }}
            </p>
            <div class="btn" @click="changeTel(item)">联 系</div>
          </div>
        </div>
        <div class="app_code">
          <img class="img" :src="appCode" alt="" />
          <p>微信扫码分享</p>
        </div>
      </div>
    </div>
    <!-- 介绍 -->
    <div style="width: 100%; height: 20px; background-color: #f5f5f5"></div>
    <div class="main" style="padding: 20px 0px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="团队案例" name="first">
          <div class="examples" v-for="(item,index) in examplesList" :key="index">
            <p class="examples_title">{{item.title}}:</p>
            <p v-html="item.content"></p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="团队成员信息" name="second">
          <p v-html="item.json ? item.json.member_content : ''"></p>
        </el-tab-pane>
        <el-tab-pane label="其他" name="third">
          <div
            class="file_style"
            @click="handleDownFile(file)"
            v-for="(file, index) in item.files"
            :key="index"
          >
            <i class="el-icon-document" style="margin-right: 10px"></i>
            <span>{{ file.filename }}</span>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <FooterInfo />

    <el-dialog title="联系方式" :visible.sync="showTel" width="20%" top="30vh">
      <p>手机号：{{ contact.tel }}</p>
      <p>联系电话：{{ contact.mobile }}</p>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeTel">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {},
      id: "",
      showTel: false,
      contact: {
        tel: "",
        mobile: "",
      },
      activeName: "first",
      examplesList: [],
      appCode: "",
    };
  },
  created() {
    // this.item = JSON.parse(this.$route.query.item);
    this.id = this.$route.query.id;
    // this.appCode =
    //   "http://gongyingliantest.yiputouzi.com/api/v1/people/app_code?path=" +
    //   "pages/index/index" +
    //   "&scene=" +
    //   this.id;
    this.appCode =
      "https://gongyinglian.yiputouzi.com/api/v1/people/app_code?path=" +
      "pages/teamDeatil/teamDeatil" +
      "&scene=" +
      this.id;
    this.getTeamDetail();
    this.getExamples();
  },
  methods: {
    async getExamples() {
      let { data: res } = await this.$http.get(
        `/v1/people/examples/${this.id}?size=1000`
      );
      this.examplesList = res.data.list;
    },
    handleClick() {},
    async getTeamDetail() {
      let { data: res } = await this.$http.get(`/v1/people/teams`, {
        params: { team_id: this.id },
      });
      this.item = res.data.list[0];
    },
    handleDownFile(file) {
      let url = file.uri;
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    },

    changeTel(item) {
      this.contact.tel = item.json.tel;
      this.contact.mobile = item.json.mobile;
      this.showTel = true;
    },
    closeTel() {
      this.contact = {};
      this.showTel = false;
    },
  },
};
</script>

<style scoped>
.contain {
  width: 1200px;
  margin: 0 auto;
}
.filter_form {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.el-form-item {
  min-width: 25%;
}
.list {
  width: 100%;
}
.item_list {
  padding: 20px 0;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}
.cover {
  width: 200px;
  height: 200px;
  margin-right: 20px;
}
.title {
  font-size: 24px;
  margin-bottom: 10px;
}
.label {
  color: #666;
  font-size: 16px;
  margin-right: 20px;
}
.type {
  color: #666;
  font-size: 16px;
}
.search {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
}
.btn_position {
  position: absolute;
  right: 0px;
  bottom: 15px;
  display: flex;
}
.btn {
  /* background: #20ab3a; */
  border: 1px solid #20ab3a;
  color: #20ab3a;
  cursor: pointer;
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
}
/deep/.el-tabs__item.is-active {
  color: #20ab3a;
}
/deep/.el-tabs__item:hover {
  color: #20ab3a;
}
/deep/.el-tabs__active-bar {
  background-color: #20ab3a;
}
.file_style {
  display: inline-block;
  width: 40%;
  padding: 20px 0px;
  color: #606266;
  cursor: pointer;
}
.file_style:hover {
  color: #20ab3a;
}

.app_code {
  width: 180px;
  height: 180px;
  text-align: center;
  color: #999;
}

.examples{
  margin-bottom: 20px;
}

.examples_title{
  /* color: #999; */

}
</style>