<template>
  <div>
    <UserHead></UserHead>
    <!-- banner -->
    <div class="banner">
      <div>banner部分</div>
    </div>
    <!-- 内容部分 -->
    <div class="settle_box">
      <div class="detailed_box_info">
        <p class="detailed_box_info_title">供应信息</p>
        <el-button type="warning" @click="addSupple">添加供应</el-button>
      </div>
      <div class="supply_box">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-input
              placeholder="请输入内容"
              v-model="query.name"
              clearable
              @clear="getSuppleList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getSuppleList"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
        <div class="supply_table">
          <el-table :data="list" stripe style="width: 100%" border>
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column prop="name" label="名称" width="140">
            </el-table-column>
            <el-table-column
              prop="category.purchase_name"
              label="类型"
              width="100"
            >
            </el-table-column>
            <el-table-column prop="remark" label="描述"> </el-table-column>
            <el-table-column label="图片">
              <template slot-scope="scope">
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    v-for="(item, index) in scope.row.images_path"
                    :key="index"
                  >
                    <img
                      style="width: 100px; height: auto; margin-right: 10px"
                      :src="item"
                      alt=""
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="editSupply(scope.row.id)"
                  >修改</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  @click="delectSupply(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="query.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="detailedListTotal"
            :hide-on-single-page="hideSinglePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <SupplyDialog :addSupplyDialog.sync="addSupplyDialog" @success="addSupplySuccess"></SupplyDialog>
    <EditSupplyDialog
      :editSupplyDialog.sync="editSupplyDialog"
      :supplyForm.sync="supplyForm"
      @success="editSupplySuccess"
    ></EditSupplyDialog>

    <FooterInfo></FooterInfo>
  </div>
</template>
<script>
import UserHead from "../components/UserHeader.vue";
import SupplyDialog from "../components/DialogSupply";
import EditSupplyDialog from "../components/editDialogSupply";
import FooterInfo from "../components/Footer";

import { Loading } from "element-ui";
export default {
  components: {
    UserHead,
    SupplyDialog,
    EditSupplyDialog,
    FooterInfo,
  },
  data() {
    return {
      query: {
        page: 1,
        size: 10,
        name: "",
      },
      list: [],
      addSupplyDialog: false,
      editSupplyDialog: false,
      supplyForm: {},
      BASE_URL: "https://gylian.yiputouzi.com/",
      detailedListTotal: 0,
      hideSinglePage: true,
    };
  },
  created() {
    this.getSuppleList();
  },

  methods: {
    async getSuppleList() {
      let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("v1/user/business/index", {
        params: { ...this.query },
      });
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
      if (res.code !== 200)
        return this.$message.error("供应信息获取失败，请稍后重试");
      this.list = res.data.list;
      this.detailedListTotal = res.data.count;
      this.list.forEach((item) => {
        item.images_path = item.images_path.map((ele) => {
          return this.BASE_URL + ele;
        });
      });
    },
    // 分页
    handleSizeChange(val) {
      this.query.size = val;
      this.getSuppleList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getSuppleList();
    },
    // 添加供应
    addSupple() {
      this.addSupplyDialog = true;
    },
    async editSupply(id) {
      let { data: res } = await this.$http.get(
        `v1/user/business/detail?id=${id}`
      );
      if (res.code !== 200)
        return this.$message.error("获取信息失败，请稍后再试");
      this.supplyForm = res.data;
      this.editSupplyDialog = true;
    },
    delectSupply(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let { data: res } = await this.$http.delete(
            `v1/user/business/delete?id=${id}`
          );
          if (res.code !== 200)
            return this.$message.error("删除失败，请稍后重试");

          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getSuppleList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 添加成功
    addSupplySuccess(){
      this.addSupplyDialog = false
      this.getSuppleList();
    },
    editSupplySuccess(){
      this.editSupplyDialog = false
      this.getSuppleList();
    }
  },
};
</script>
<style scoped>
.banner {
  width: 1200px;
  height: 240px;
  line-height: 240px;
  text-align: center;
  margin: 20px auto;
  background: #d8d8d8;
}

.settle_box {
  width: 100%;
  background: #fff;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
}

.supply_box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 40px;
}
.supply_table {
  margin-top: 30px;
}

.pagination {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
</style>