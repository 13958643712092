<template>
  <div>
    <el-upload
      :action="domain"
      :data="qiNiuData"
      class="avatar-uploader"
      :show-file-list="false"
      :on-error="handleError"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <el-image v-if="imageUrl" :src="imageUrl" class="avatar">
        <div slot="placeholder" class="image-slot"></div>
      </el-image>

      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<script>
export default {
  props: {
    uploadType: {
      type: Boolean,
      default: false,
    },
    imagePath: {
      type: String,
    },
  },
  data() {
    return {
      domain: "https://upload-z2.qiniup.com",
      BASE_URL: "https://gylian.yiputouzi.com/",
      qiNiuData: {},
      defaultImg: [],
      myFileUrl: "",
      images_path: [],
      isShowImg: false,
      imageUrl: "",
      isDefault: false,
    };
  },
  created() {
    this.getQiNiuToken();
  },
  watch: {
    imagePath() {
      this.imageUrl = JSON.parse(this.imagePath).url;
      // console.log(JSON.parse(this.imagePath));
    },
  },

  methods: {
    async getQiNiuToken() {
      let { data: res } = await this.$http.get("v1/common/qiniu/token");
      this.qiNiuData.token = res.data.token;
    },
    // 上传图片

    // 上传文件时的钩子函数
    beforeAvatarUpload(file) {
      if (this.uploadType) {
        console.log(this.uploadType);
        return this.setFileValidation(file);
      }
      console.log(this.uploadType);
      return this.setFileValidationImg(file);
    },
    // 文件验证
    setFileValidationImg(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      const isJPG = (type == "jpg" || type == "png" || type == "gif") && true;
      // const isJPG = file.type === "image/jpeg";
      if (!isJPG) {
        this.$message.error("上传的文件不符合规范!,支持格式:png/jpg/gif");
      }
      if (!isLt2M) {
        this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "error",
          offset: 60,
        });
      }
      return isJPG && isLt2M;
    },
    // 文件验证
    setFileValidation(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      const isJPG = (type == "jpg" || type == "png" || type == "gif") && true;
      // const isJPG = file.type === "image/jpeg";

      if (!isLt2M) {
        this.$message({
          message: "上传图片大小不能超过 5MB!",
          type: "error",
          offset: 60,
        });
      }
      return isLt2M;
    },

    // 返回错误
    handleError() {
      Message({
        message: "文件上传失败",
        type: "error",
        duration: 2 * 1000,
      });
    },

    // 成功返回
    handleAvatarSuccess(res, file) {
      // let arr = file.name.split(".");
      // let type = arr[arr.length - 1];
      // const isJPG = (type == "jpg" || type == "png" || type == "gif") && true;
      this.imageUrl = URL.createObjectURL(file.raw);
      // this.isDefault = false;

      this.myFileUrl = this.BASE_URL + res.hash;
      let img = { hash: res.hash, url: this.myFileUrl };
      this.images_path = img;
      this.$emit("images_path", this.images_path);
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  font-size: 28px;
  color: #8c939d;
  width: 170px;
  height: 170px;
  line-height: 170px;
  text-align: center;
}
.image-slot {
  background: #8c939d;
  width: 170px;
  height: 170px;
  line-height: 170px;
  text-align: center;
}
.avatar {
  width: 170px;
  height: 170px;
  display: block;
  border-radius: 6px;
  overflow: hidden;
}
</style>