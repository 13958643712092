<template>
  <div>
    <div class="footer_box">
      <div class="footer_box_info">
        <!-- <ul class="footer_box_info_nav_list">
          <li>关于我们</li>
          <li>｜</li>
          <li>联系我们</li>
          <li>｜</li>
          <li>友情链接</li>
          <li>｜</li>
          <li>帮助中心</li>
          <li>｜</li>
          <li>意见反馈</li>
          <li>｜</li>
          <li>网站地图</li>
        </ul> -->
        <p>法律声明© 2021 园林惠平台保留所有权利</p>
        <p>
          园林行业痛点解决平台
          <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备17056947号-3</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
   name:'FooterInfo',
  data() {
    return {};
  },
};
</script>
<style scoped>
.footer_box {
  width: 100%;
  background: #f5f5f5;
  /* margin-top: 20px; */
}
.footer_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;
}

.footer_box_info_nav_list {
  list-style: none;
  display: flex;
  justify-content: center;
}
.footer_box_info_nav_list > li {
  margin: 6px;
  color: #666666;
  font-size: 14px;
}
.footer_box_info > p {
  margin-top: 10px;
  color: #666666;
  font-size: 14px;
}
a{
  color: #666666;
  text-decoration: none;
}
</style>>