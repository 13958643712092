<template>
  <div class="bg">
    <HeaderInfo />
    <div class="login_box">
      <el-tabs v-model="activeName" stretch class="tabs_box">
        <el-tab-pane label="账号登录" name="first">
          <el-form
            ref="loginFormRef"
            :model="loginForm"
            label-width="0px"
            class="login_form"
          >
            <!-- 用户名 -->
            <el-form-item
              class="phone"
              prop="mobile"
              :rules="[
                { required: true, message: '请输入手机号', trigger: 'blur' },
              ]"
            >
              <el-input
                prefix-icon="el-icon-user"
                v-model="loginForm.mobile"
                placeholder="请输入手机号"
              >
              </el-input>
              <el-button
                type="success"
                class="phone_code"
                v-if="!isDisabled"
                @click="getPhoneCode"
                >{{ content }}</el-button
              >
              <el-button
                type="info"
                disabled
                class="phone_code"
                v-if="isDisabled"
                @click="getPhoneCode"
                >{{ content }}</el-button
              >
            </el-form-item>
            <el-form-item
              prop="code"
              :rules="[
                { required: true, message: '请输入验证码', trigger: 'blur' },
              ]"
            >
              <el-input
                prefix-icon="el-icon-lock"
                v-model="loginForm.code"
                placeholder="请输入验证码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div class="btns" @click="login">登 录</div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="扫码登陆" name="second">
          <div>
            <wxlogin
              :appid="appid"
              :scope="'snsapi_login'"
              :theme="'black'"
              :redirect_uri="
                encodeURIComponent(
                  `http://gongyinglianweb.yiputouzi.com/#${$route.fullPath}`
                )
              "
              :href="bast64css"
              rel="external nofollow"
            >
            </wxlogin>
            <!-- <wxlogin
              :appid="appid"
              :scope="'snsapi_login'"
              :theme="'black'"
              :redirect_uri="
                encodeURIComponent(
                  `http://gongyinglianwebtest.yiputouzi.com/#${$route.fullPath}`
                )
              "
              :href="bast64css"
              rel="external nofollow"
            >
            </wxlogin> -->
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <bind-mobile
      :bindMobileShow="bindMobileShow"
      :bindToken="bindToken"
    ></bind-mobile>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import wxlogin from "vue-wxlogin";

import bindMobile from "../components/BindMobile.vue";
export default {
  components: { wxlogin, bindMobile },

  data() {
    return {
      activeName: "first",
      loginForm: {
        mobile: "",
        code: "",
      },
      isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
      content: "获取验证码",
      timer: null,
      count: "",
      apply_type_id: "",
      typeOptions: [],
      suppleProps: {
        expandTrigger: "hover",
        value: "id",
        label: "name",
      },
      bast64css:
        "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMTBweDt9Ci5pbXBvd2VyQm94IC5pY29uMzhfbXNnLnN1Y2Mge2Rpc3BsYXk6IG5vbmV9Ci5pbXBvd2VyQm94IC5pY29uMzhfbXNnLndhcm4ge2Rpc3BsYXk6IG5vbmV9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9Cg==",
      appid: "wx801327bb29dc780f", // 后端提供
      redirect_uri: `http://gongyinglianwebtest.yiputouzi.com`, // 后端提供
      code: "",
      bindMobileShow: false,
      bindToken: "",
    };
  },
  created() {
    // this.href = window.location.href;
  },
  watch: {
    $route: {
      handler(route) {
        if (route.query.code) {
          console.log("请求");
          this.code = route.query.code;
          this.getCode();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setToken: "account/setToken",
      setUser: "account/setUser",
    }),
    async getCode() {
      const { data: res } = await this.$http.get("v1/wxQrCodeLogin", {
        params: { code: this.code },
      });

      if (res.data.bindToken) {
        this.$router.push({ query: {} });
        this.bindToken = res.data.bindToken;
        this.bindMobileShow = true;
      } else {
        this.$router.push({ query: {} });
        this.saveLoginData(res.data);
        this.$router.push("/");
      }
    },

    async getPhoneCode() {
      if (!this.loginForm.mobile || this.loginForm.mobile == "") {
        return this.$message.error("请输入手机号");
      }
      if (!/^1[34578]\d{9}$/.test(this.loginForm.mobile)) {
        return this.$message.error("请输入正确的手机号");
      }
      // 请求后台发送验证码至手机;
      this.getVerifyCode(this.loginForm.mobile);
      const TIME_COUNT = 60;
      this.count = TIME_COUNT;
      this.timer = window.setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          // 倒计时时不可点击
          this.isDisabled = true;
          // 计时秒数
          this.count--;
          // 更新按钮的文字内容
          this.content = this.count + "s后重新获取";
        } else {
          // 倒计时完，可点击
          this.isDisabled = false;
          // 更新按钮文字内容
          this.content = "获取短信验证码";
          // 清空定时器!!!
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },

    // 获取验证码
    async getVerifyCode(mobile) {
      let { data: res } = await this.$http.post(`v1/send/code`, {
        mobile: mobile,
        send_type: 2,
      });

      if (res.code !== 200)
        return this.$message.error("验证码发送失败，请稍后重试");
      this.$message.success("验证码发送成功");
      this.isDisabled = false;
    },

    //提交表单
    login() {
      const Base64 = require("js-base64").Base64;
      // this.loginForm.username = Base64.encode(this.loginForm.username);
      // this.loginForm.password = Base64.encode(this.loginForm.password);

      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post(
          "v1/mobile/login",
          this.loginForm
        );
        if (res.code === 200) {
          this.saveLoginData(res.data);
        } else {
          console.log(res);
        }
        // this.$router.push("/");
        this.$router.go(-1);
      });
    },
    //将登录后的信息通过vuex存储到本地中
    saveLoginData(data) {
      // token
      this.setToken(data.token);
      // 用户信息
      this.setUser(data.user);
    },
  },
};
</script>
<style scoped>
.bg {
  width: 100%;
  height: 100vh;
  background-color: #c0e2cc;
}
.login_box {
  width: 300px;
  height: 360px;
  background: #ffffff;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login_title {
  text-align: center;
  margin-top: 20px;
}
.tabs_box {
  padding: 10px 0;
}
.login_form {
  /* position: absolute;
  top: 80px; */
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
}
.btns {
  /* display: flex;
  justify-content: flex-end; */
  /* width: 100%; */
  /* padding: 0px 20px; */
}
.btns {
  background: #20ab3a;
  color: #fff;
  cursor: pointer;
  width: 100%;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 20px;
}

/* /deep/.el-button--primary {
    color: #FFF;
    background-color: #20ab3a;
    border-color: #20ab3a;
}
/deep/.el-button:hover {
    color: #FFF;
    background-color: #20ab3a;
} */

/deep/.el-tabs__item.is-active {
  color: #20ab3a;
}
/deep/.el-tabs__item:hover {
  color: #20ab3a;
}
/deep/.el-tabs__active-bar {
  background-color: #20ab3a;
}

.phone {
  position: relative;
}
.phone_code {
  height: 100%;
  position: absolute;
  right: 0px;
}
.save {
  width: 30%;
  margin-top: 20px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

/* .el-form {
  margin-top: 0px;
  padding-right: 20px;
}
.el-form-item {
  width: 100%;
  margin-bottom: 5px !important;
} */

.el-button {
  line-height: 0.6 !important;
}
.el-select {
  width: 100%;
}
.el-message--error {
  width: 80px !important;
}
</style>
