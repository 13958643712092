<template>
  <div>
    <el-dialog
      title="报价详情"
      :visible="quoteDialogVisible"
      width="50%"
      top="15vh"
      :before-close="handleClose"
    >
      <el-form ref="itemFormRef" :model="item" label-width="80px">
        <el-form-item
          label="名称"
          prop="name"
          :rules="[
            {
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="item.name"></el-input>
        </el-form-item>
        <el-form-item
          label="数量"
          prop="amount"
          :rules="[
            {
              required: true,
              message: '请输入数量',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="item.amount"></el-input>
        </el-form-item>
        <el-form-item
          label="单位"
          prop="unit"
          :rules="[
            {
              required: true,
              message: '请输入单位',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="item.unit"></el-input>
        </el-form-item>
        <el-form-item label="规格信息">
          <el-input type="textarea" v-model="item.spec_remark"></el-input>
        </el-form-item>
        <el-form-item label="详细描述">
          <el-input type="textarea" v-model="item.other"></el-input>
        </el-form-item>
        <el-form-item label="相关附件">
          <el-upload
            :action="domain"
            :data="qiNiuData"
            class="upload-idCard"
            list-type="picture-card"
            :file-list="defaultImg"
            ref="imgRef"
            :on-preview="handleShow"
            :on-error="handleError"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :before-upload="beforeAvatarUpload"
            :limit="6"
            multiple
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="isShowImg" width="50%" size="tiny">
            <img width="100%" :src="myFileUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    quoteDialogVisible: {
      type: Boolean,
      default: false,
    },
    DetailedList: {
      type: Object,
    },
  },
  data() {
    return {
      domain: "https://upload-z2.qiniup.com",
      BASE_URL: "https://gylian.yiputouzi.com/",
      qiNiuData: {},
      defaultImg: [],
      myFileUrl: "",
      images_path: [],
      isShowImg: false,
      item: {
        name: "",
        unit: "",
        amount: "",
        other: "",
        spec_remark: "",
      },
    };
  },
  created() {
    this.getQiNiuToken();
  },
  watch: {
    quoteDialogVisible() {
      this.item.purchase_id = this.DetailedList.purchase_id;
      this.item.id = this.DetailedList.id;
      this.item.name = this.DetailedList.name;
      this.item.amount = this.DetailedList.amount;
      this.item.unit = this.DetailedList.unit;
      this.item.other = this.DetailedList.other;
      this.item.spec_remark = this.DetailedList.spec_remark;
    },
    DetailedList() {
      if (this.DetailedList.images_path) {
        this.DetailedList.images_path.forEach((item) => {
          let obj = new Object();
          obj.response = {};
          let img_path = {};
          obj.response.hash = item.hash;
          obj.url = this.BASE_URL + item.hash;
          img_path.hash = item.hash;
          img_path.url = this.BASE_URL + item.hash;
          this.images_path.push(img_path);
          this.defaultImg.push(obj);
        });
      }
    },
  },
  methods: {
    handleClose() {
      this.defaultImg = [];
      this.images_path = [];
      this.$emit("update:item", {});
      this.$emit("update:DetailedList", {});
      this.$emit("update:quoteDialogVisible", false);
    },
    async getQiNiuToken() {
      let { data: res } = await this.$http.get("v1/common/qiniu/token");
      this.qiNiuData.token = res.data.token;
    },
    imagesPath(e) {
      this.item.images_path = JSON.stringify(e);
    },
    async save() {
      this.$refs.itemFormRef.validate(async (valid) => {
        if (!valid) return;
        let { data: res } = await this.$http.put(`purchase/other/update`, {
          purchase_id: this.item.purchase_id,
          id: this.item.id,
          name: this.item.name,
          unit: this.item.unit,
          amount: this.item.amount,
          other: this.item.other,
          spec_remark: this.item.spec_remark,
          images_path: JSON.stringify(this.images_path),
        });
        this.$message.success("修改成功");
        this.$emit("update");
        this.handleClose();
      });
    },

    // 上传图片

    // 上传文件时的钩子函数
    beforeAvatarUpload(file) {
      // console.log("上传文件时的钩子函数");
      return this.setFileValidation(file);
    },
    // 文件验证
    setFileValidation(file) {
      this.qiNiuData.key = file.name;
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      let isJPG = (type == "jpg" || type == "png" || type == "gif") && true;
      !isJPG &&
        this.$message.error("上传的文件不符合规范!,支持格式:png/jpg/gif");
      return isJPG;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多只能上传6张图片，请删除后再添加`);
    },
    // 选择时触发
    handleChange(file) {
      // console.log("选择时触发", file);
      // this.hideUpload = this.defaultImg.length >= this.limitCount;
    },
    // 成功之后判断类型
    isSuccessFileType(key) {
      // console.log("成功之后判断类型");
      // let arr = key.split(".");
      // return arr[arr.length - 1];
    },
    // 返回错误
    handleError() {
      Message({
        message: "文件上传失败",
        type: "error",
        duration: 5 * 1000,
      });
    },

    // 成功返回
    handleAvatarSuccess(res, file) {
      // console.log("成功返回", res);
      this.myFileUrl = this.BASE_URL + res.hash;
      let img = { hash: res.hash, url: this.myFileUrl };
      this.images_path.push(img);
    },

    handleRemove(file, fileList) {
      console.log("删除", file, fileList);
      if (fileList.length > 0) {
        let imgUrl = {};
        this.images_path = [];
        fileList.forEach((item) => {
          imgUrl = {
            hash: item.response.hash,
            url: this.BASE_URL + item.response.hash,
          };
          this.images_path.push(imgUrl);
        });
      } else {
        this.images_path = [];
      }
      // console.log(this.images_path);
    },
    // 点击文件列表中已上传的文件时的钩子
    async handleShow(file) {
      this.myFileUrl = file.url;
      this.isShowImg = true;
      // this.myFileUrl = this.BASE_URL + this.myHashUrl;
      // this.isType && (await this.openFile());
    },
  },
};
</script>
<style scoped>
</style>