<template>
  <div>
    <!-- 头部状态条 -->
    <HeaderInfo></HeaderInfo>
    <!-- 信息部分 -->
    <div>
      <div class="purchase_info">
        <!-- 详情 -->
        <div class="purchase_detail">
          <div class="purchase_detail_banner">
            <!-- <img src="" alt="" /> -->
            <el-carousel :interval="3000" :arrow="arrow">
              <el-carousel-item
                v-for="(img, index) in list.banner"
                :key="index"
              >
                <img v-bind:src="img" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="purchase_info_detail">
            <div class="purchase_title">
              <div class="purchase_title">
                <p class="purchase_name">{{ list.purchase_name }}</p>
                <p class="purchase_label">
                  {{ list.purchase_status === 1 ? "报价中" : "报价结束" }}
                </p>
              </div>
              <p class="purchase_count">采购 {{ list.purchase_count }} 件</p>
            </div>
            <p class="deadline">截止时间：{{ list.deadline }}</p>
            <p class="deadline">
              本次交易支持融资申请 （<span
                class="purchase_financing"
                @click="financingDialogVisible = true"
                >了解详情</span
              >）
              <span class="financing_btn" @click="financingVisible = true"
                >融资申请</span
              >
            </p>
            <p class="purchase_demand">采购要求</p>
            <div class="purchase_demand_info">
              <p>
                <span>发布单位：{{ list.company_name }}</span>
                <span>
                  联系方式：{{ list.contact_name }}
                  {{ list.contact_phone }}
                </span>
              </p>
              <p>
                <span>用材地址：{{ list.address }}</span>
                <span>进场车限制：{{ list.car_limit }}</span>
              </p>
              <p>
                <span>进场日期：{{ list.enter_limit }}</span>
                <span>报价要求：{{ quote_priceName }}</span>
              </p>
              <p>
                <span>发票要求：{{ invoiceName }}</span>
                <span>含税要求：{{ taxName }}</span>
                <span>税点：{{ list.tax_point }}</span>
              </p>
              <p>其他说明：{{ list.other ? list.other : "无" }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="detailed_box">
        <div class="detailed_box_title">
          <div class="detailed_box_info">
            <p class="detailed_box_info_title">采购清单</p>
            <div class="detailed_box_info_btns" v-if="!cancelShow">
              <el-button
                type="warning"
                @click="handleDownFile"
                style="margin-right: 20px"
                >一键报价</el-button
              >
              <el-upload
                :action="baseUrl + 'quoted/import'"
                :headers="SetHeaders"
                :data="uploadData"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :file-list="fileList"
                :disabled="uploadDisableds"
              >
                <el-button type="warning" @click="verification"
                  >批量提交报价</el-button
                >
              </el-upload>
            </div>
          </div>
        </div>
        <div class="detailed_list_box">
          <div
            class="detailed_list"
            v-for="(item, index) in detailedList"
            :key="index"
          >
            <div class="detailed_number">
              {{ cancelShow ? item.purchase_detail.id : item.id }}
            </div>
            <div>
              <div class="detailed_right">
                <div class="detailed_name">
                  <p>
                    {{ cancelShow ? item.purchase_detail.name : item.name }}
                  </p>
                  <el-button
                    size="mini"
                    type="danger"
                    v-if="cancelShow"
                    @click="cancelQuote(item)"
                    >取消报价</el-button
                  >
                </div>
                <p>
                  <span class="unit"
                    >单位：{{
                      cancelShow ? item.purchase_detail.unit : item.unit
                    }}</span
                  >
                  <span
                    >数量：{{
                      cancelShow ? item.purchase_detail.amount : item.amount
                    }}</span
                  >
                </p>
                <p>
                  规格：{{
                    cancelShow
                      ? item.purchase_detail.spec_remark
                      : item.spec_remark
                  }}
                </p>
                <p>
                  备注：{{
                    cancelShow ? item.purchase_detail.other : item.other
                  }}
                </p>
              </div>
              <!-- 表单 -->
              <div class="detailed_form">
                <ul class="detailed_form_label">
                  <li style="margin-right: 55px">含税单价</li>
                  <li style="margin-right: 55px">供应数量</li>
                  <li style="margin-right: 55px">税点</li>
                  <li style="margin-right: 55px">不含税单价</li>
                  <li style="margin-right: 55px">不含税合价</li>
                  <li style="margin-right: 90px">含税合价</li>
                  <li style="margin-right: 90px">供货地址</li>
                  <li style="margin-right: 80px">其他说明</li>
                  <li style="margin-right: 50px">货品图片</li>
                  <li>操作</li>
                </ul>
                <!-- 表单 -->
                <DetailedForm
                  v-if="!cancelShow"
                  :purchase_id="id"
                  :purchase_detail_id="item.id"
                  :cancelShow="cancelShow"
                  :detailedList="detailedList"
                  :amount="item.amount"
                  :tax_point="item.purchase.tax_point"
                ></DetailedForm>
                <DetailedForm
                  v-if="cancelShow"
                  :purchase_id="id"
                  :orderId="item.id"
                  :purchase_detail_id="item.purchase_detail_id"
                  :cancelShow="cancelShow"
                  :detailedList="detailedList"
                  :amount="item.amount"
                ></DetailedForm>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="query.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="detailedListTotal"
            :hide-on-single-page="hideSinglePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 融资信息 -->
    <el-dialog
      title="融资申请"
      :visible.sync="financingDialogVisible"
      width="30%"
      top="30vh"
      center
      :before-close="showfinancingDialog"
    >
      <p v-html="financingContent"></p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="financingDialogVisible = false"
          >我知道了</el-button
        >
      </span>
    </el-dialog>

    <!-- 融资申请 -->
    <el-dialog
      title="融资申请"
      :visible.sync="financingVisible"
      width="30%"
      top="30vh"
      :before-close="closeFinancingDialog"
    >
      <el-form
        :model="financingForm"
        ref="financingFormRef"
        label-width="100px"
        label-position="top"
        class="demo-ruleForm"
      >
        <el-form-item
          label="名称（公司或合作社）"
          prop="company_name"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <el-input v-model="financingForm.company_name"></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="mobile"
          :rules="[
            { required: true, message: '请输入联系电话', trigger: 'blur' },
          ]"
        >
          <el-input v-model="financingForm.mobile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeFinancingDialog">取 消</el-button>
        <el-button type="primary" @click="postFinancing">提 交</el-button>
      </span>
    </el-dialog>
    <!-- <UploadDialog :showDialog.sync="showDialog" :id="id"></UploadDialog> -->
    <!-- 页脚部分 -->
    <FooterInfo></FooterInfo>
  </div>
</template>
<script>
import UploadDialog from "../components/UploadDialog";
import DetailedForm from "../components/DetailedForm";
import { Loading } from "element-ui";
export default {
  components: {

    DetailedForm,
    UploadDialog,
  },
  data() {
    return {
      id: "",
      list: {},
      arrow: "never",
      quote_priceName: "",
      invoiceName: "",
      taxName: "",
      detailedList: [],
      detailedListTotal: 0,
      hideSinglePage: true,
      query: {
        page: 1,
        size: 10,
      },
      detailedForm: {},
      showDialog: false,
      financingDialogVisible: false,
      financingContent: "",
      financingVisible: false,
      financingForm: {},
      cancelShow: false,

      uploadDisableds: true,
      SetHeaders: { token: "" },
      uploadData: {},
      fileList: [],
      // imgFile: "",
      baseUrl: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getPurchaseDetail();
    this.getFinancing();
    if (this.$route.query.cancelShow) {
      this.cancelShow = true;
      // console.log(this.cancelShow, this.$route.query.cancelShow);
    }
    this.baseUrl = process.env.VUE_APP_API_URL;
  },
  methods: {
    // 获取采购单详情
    async getPurchaseDetail() {
      let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("v1/purchase/find", {
        params: { id: this.id },
      });
      this.list = res.data;
      if (this.list.banner > 1) {
        this.arrow = "always";
      }
      this.quote_priceName = res.data.quote_price.name;
      this.invoiceName = res.data.invoice.name;
      if (res.data.tax) {
        this.taxName = res.data.tax.name;
      } else {
        this.taxName = "不含税";
      }
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
      if (!this.cancelShow) {
        this.getDetailedList();
      } else {
        this.getQuoteList();
      }
    },
    // 获取采购清单
    async getDetailedList() {
      let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("v1/purchase/other/index", {
        params: { purchase_id: this.list.id, ...this.query },
      });
      this.detailedList = res.data.list;
      // console.log(this.detailedList)
      this.detailedListTotal = res.data.count;
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
    },
    // 获取报价清单详情
    async getQuoteList() {
      console.log(this.list.id);
      let { data: res } = await this.$http.get("quoted/index", {
        params: { purchase_id: this.list.id, ...this.query },
      });
      this.detailedList = res.data.list;
      console.log(this.detailedList);
      this.detailedListTotal = res.data.count;
    },
    // 分页
    handleSizeChange(val) {
      this.query.size = val;
      this.detailedList = {};
      if (!this.cancelShow) {
        this.getDetailedList();
      } else {
        this.getQuoteList();
      }
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.detailedList = {};
      if (!this.cancelShow) {
        this.getDetailedList();
      } else {
        this.getQuoteList();
      }
    },

    // 融资信息关闭
    showfinancingDialog() {
      this.financingDialogVisible = false;
    },

    // 融资申请关闭
    closeFinancingDialog() {
      this.financingVisible = false;
      this.financingForm = {};
    },
    // 融资申请信息提交
    postFinancing() {
      let token = localStorage.getItem("TOKEN");
      if (!token) return this.$message.warning("请先登录 ！");
      this.$refs.financingFormRef.validate(async (valid) => {
        if (!valid) return;
        let { data: res } = await this.$http.post("v1/financing/basic/create", {
          ...this.financingForm,
        });
        if (res.code !== 200)
          return this.$message.error("提交失败，请稍后重试");
        this.$message.success("提交成功，请等待审核");
        this.financingVisible = false;
        this.financingForm = {};
      });
    },

    // 获取融资申请内容
    async getFinancing() {
      let { data: res } = await this.$http.get("v1/common/finance");
      if (res.code !== 200) return this.$message.error("融资详情获取失败");
      this.financingContent = res.data.content;
    },

    // 下载文件
    async handleDownFile() {
      let token = localStorage.getItem("TOKEN");
      if (!token) return this.$message.warning("请先登录 ！");
      let { data: res } = await this.$http.get(
        `${this.baseUrl}quoted/export?purchase_id=${this.id}`
      );
      if (res.code !== 200) return this.$message.error("一键报价获取失败");
      // console.log(res.data.url);
      let url = res.data.url;
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      // link.setAttribute("download", "excel.xls");
      document.body.appendChild(link);
      link.click();
    },

    // 上传前先验证是否有采购单
    verification() {
      let token = localStorage.getItem("TOKEN");
      if (!token) return this.$message.warning("请先登录 ！");
      this.uploadDisableds = false;
    },

    // 上传文件时的钩子函数
    beforeAvatarUpload(file) {
      let token = localStorage.getItem("TOKEN");
      this.SetHeaders.token = JSON.parse(token);
      this.uploadData.purchase_id = this.id;
      this.uploadData.file = file.name;

      return this.setFileValidation(file);
    },
    // 文件验证
    setFileValidation(file) {
      let arr = file.name.split(".");
      let type = arr[arr.length - 1];
      let isJPG = type == "xlsx" && true;
      !isJPG &&
        this.$message.error("上传的文件不符合规范! 请上传格式为xlsx文件");
      return isJPG;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
    },
    // uploadFile(file) {
    //   // console.log(file.file.name);
    //   let formData = new FormData();
    //   formData.append("purchase_id", this.id);
    //   formData.append("file", file.file.name);
    // },

    handleAvatarSuccess(res, file) {
      let loadingInstance = Loading.service();
      setTimeout(() => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
      }, 200);
      if (res.code !== 200) return this.$message.error("上传失败，请稍后再试");
      this.$message.success("上传成功，请前往我的报价查看详情");
      // this.$confirm("是否前往我的报价查看", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   this.deleteQuote(item.id);
      // });
    },

    // 提交报价
    // openQuoted() {
    //   let token = localStorage.getItem("TOKEN");
    //   if (!token) return this.$message.warning("请先登录 ！");
    //   this.showDialog = true;
    // },

    // 取消报价
    cancelQuote(item) {
      console.log(item);
      this.$confirm("取消后将从列表中删除，确定取消吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.deleteQuote(item.id);
      });
    },
    async deleteQuote(id) {
      let { data: res } = await this.$http.put("quoted/status", {
        purchase_order_id: id,
      });
      if (res.code !== 200) return this.$message.error("取消失败，请稍后再试");
      this.$message.success("取消成功");
      this.getQuoteList();
    },
  },
};
</script>
<style scoped>
.purchase_info {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 20px;
}
.purchase_detail {
  display: flex;
  padding-bottom: 20px;
}
.purchase_detail_banner {
  width: 280px;
  height: 280px;
}
.purchase_info_detail {
  margin-left: 20px;
  width: 900px;
}
.purchase_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.purchase_name {
  font-size: 26px;
  color: #333;
}
.purchase_label {
  font-size: 17px;
  background: #20ab3a;
  color: #fff;
  padding: 2px 5px;
  margin-left: 10px;
}
.purchase_count {
  font-size: 22px;
  color: #dc5b56;
}
.deadline {
  color: #333;
  font-size: 16px;
  margin-top: 10px;
}
.purchase_financing {
  color: #20ab3a;
  cursor: pointer;
}
.financing_btn {
  color: #f97d03;
  cursor: pointer;
  margin-left: 10px;
}
.purchase_demand {
  margin-top: 30px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.purchase_demand_info {
  margin-top: 15px;
}
.purchase_demand_info > p {
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}
.purchase_demand_info > p > span {
  margin-right: 20px;
}

/* 清单列表 */
.detailed_box {
  width: 100%;
  background: #fff;
}
.detailed_box_title {
  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
}
.detailed_box_info_btns {
  display: flex;
}
/* .detailed_box_info_btns > div {
  color: #fff;
  font-size: 18px;
  background: #f97d03;
  padding: 6px 10px;
  margin-left: 20px;
  border-radius: 4px;
} */
.detailed_list_box {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}
.detailed_list {
  width: 100%;
  background: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
}
.detailed_number {
  background: #20ab3a;
  color: #fff;
  /* padding: 5px; */
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.detailed_right {
  margin-left: 15px;
}
.detailed_right > p {
  margin-bottom: 6px;
  color: #666666;
  font-size: 14px;
}
.detailed_name {
  color: #333 !important;
  font-size: 20px !important;
  display: flex;
  justify-content: space-between;
}
.unit {
  margin-right: 20px;
}

.detailed_form {
  margin-left: 15px;
  margin-top: 20px;
}

.detailed_form_label {
  background: #e5e5e5;
  color: #666666;
  padding: 12px 20px;
  font-size: 13px;
  list-style: none;
  width: 1100px;
  display: flex;
}
.pagination {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
}
</style>