<template>
  <div>
    <UserHead></UserHead>
    <!-- banner -->
    <div class="banner">
      <div>banner部分</div>
    </div>
    <!-- 内容部分 -->
    <div class="settle_box">
      <div class="detailed_box_info">
        <p class="detailed_box_info_title">采购清单</p>
        <el-button type="warning" @click="addPurchase">添加采购单</el-button>
      </div>
      <div class="supply_box">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-input
              placeholder="请输入内容"
              v-model="query.purchase_name"
              clearable
              @clear="getPurchaseList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getPurchaseList"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
        <div class="supply_table">
          <el-table :data="purchaseList" stripe style="width: 100%" border>
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column
              prop="purchase_name"
              label="采购单名称"
              width="220"
            >
            </el-table-column>
            <el-table-column prop="category.name" label="类型" width="80">
            </el-table-column>
            <el-table-column prop="company_name" label="发布单位" width="240">
            </el-table-column>
            <el-table-column prop="deadline" label="截止时间" width="140">
            </el-table-column>
            <el-table-column label="报价数量">
              <template slot-scope="scope">
                <span>{{ scope.row.quoted_count }}条报价</span>
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.is_status"
                  @change="changeSwitch(scope.row)"
                  :active-value="active_value"
                  :inactive-value="inactive_value"
                  active-color="#13ce66"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="320">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="editPurchase(scope.row.id)"
                  >修改采购单</el-button
                >
                <el-button size="mini" @click="openDetailedList(scope.row.id)"
                  >采购单详情</el-button
                >
                <el-button size="mini" @click="openQuotedList(scope.row.id)"
                  >报价明细</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="query.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="detailedListTotal"
            :hide-on-single-page="hideSinglePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <FooterInfo></FooterInfo>
  </div>
</template>
<script>
import UserHead from "../components/UserHeader.vue";
import FooterInfo from "../components/Footer";
import { Loading } from "element-ui";
export default {
  components: {
    UserHead,
    FooterInfo,
  },
  data() {
    return {
      query: {
        page: 1,
        size: 10,
        purchase_name: "",
      },
      purchaseList: [],
      detailedListTotal: 0,
      hideSinglePage: true,
      active_value: 1,
      inactive_value: 2,
    };
  },
  created() {
    this.getPurchaseList();
  },
  methods: {
    async getPurchaseList() {
      let loadingInstance = Loading.service();
      let { data: res } = await this.$http.get("purchase/list", {
        params: { ...this.query },
      });
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
      if (res.code !== 200)
        return this.$message.error("列表获取失败，请稍后重试");
      this.purchaseList = res.data.list;
      this.detailedListTotal = res.data.count;
    },
    // 切换状态
    async changeSwitch(row) {
      let { data: res } = await this.$http.put(
        `purchase/status?id=${row.id}&is_status=${row.is_status}`
      );
      if (res.code !== 200)
        return this.$message.error("状态切换失败，请稍后重试");
      this.$message.success("状态切换成功");
      this.getPurchaseList();
    },
    // 分页
    handleSizeChange(val) {
      this.query.size = val;
      this.getPurchaseList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getPurchaseList();
    },
    // 添加采购单
    addPurchase() {
      this.$router.push("/addPurchase");
    },
    openDetailedList(id) {
      this.$router.push({
        path: "/purchaseDetailedList",
        query: {
          id: id,
        },
      });
    },
    editPurchase(id) {
      this.$router.push({
        path: "/addPurchase",
        query: {
          id: id,
        },
      });
    },
    openQuotedList(id) {
      this.$router.push({
        path: "/purchaseQuoteList",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped>
.banner {
  width: 1200px;
  height: 240px;
  line-height: 240px;
  text-align: center;
  margin: 20px auto;
  background: #d8d8d8;
}

.settle_box {
  width: 100%;
  background: #fff;
}
.detailed_box_info {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f5f5f5;
}
.detailed_box_info_title {
  font-size: 24px;
  color: #f97d03;
  font-weight: bold;
}

.supply_box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 40px;
}
.supply_table {
  margin-top: 30px;
}

.pagination {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
</style>